import React, {useState} from 'react';
import {
    DivBoxLenteSC,
    DivBoxProfileInfoSC,
    DivWrapperLeftPanelSC,
} from "../../styles/styles.left_panel";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import hostName from "../../api/hostName";
import TabList from "./tab_list";
import {LuLogOut} from "react-icons/lu";
import {DivBackLoginSC, DivItemTempSC, DivLogoutSc} from "../../styles/styles.layout";
import { IoPersonAddOutline } from "react-icons/io5";
import { DivFormWrapperSC, SpanTextSC} from "../../styles/styles.signin";
import {useNavigate} from "react-router-dom";
import {getStatusLoad, getUser} from "../../store/slices/userSlice,";
import {axiosGet, axiosPost} from "../../api/fetch";
import {getUsers} from "../../store/slices/usersSlice";
import {getNAProjects, getProjects} from "../../store/slices/projectsSlice";
import ProfileSelect from "../selectUser";
import { FaRegCheckCircle } from "react-icons/fa";

import {Button, Input, User} from "@nextui-org/react";
import { SiGnuprivacyguard } from "react-icons/si";
import {Chip} from "@nextui-org/react";
import {Tooltip} from "@nextui-org/react";




const Panel = (props: any) => {
    const {
        user
    } = useAppSelector(state => (state.userReducer))
    const [email, setEmail] = useState<string>('')
    const [pass, setPass] = useState<string>('')
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const navigate = useNavigate();
    const dispatch = useAppDispatch()


    const onSubmit = async () => {
        if (email && pass) {
            if (localStorage.getItem('token')) {
                hostName.updateHost(hostName.staticDevHost)
            }
            if (localStorage.getItem('tokenDev')) {
                hostName.updateHost(hostName.staticHost)
            }
            dispatch(getStatusLoad(true))
            const result = await axiosPost({
                url: `${hostName.getHost()}/auth/signin`,
                // url: `http://localhost:8083/auth/signin`,
                data: {
                    email: email,
                    password: pass,
                },
            })
            if (result.status === 200) {


                const result2 = await axiosPost({
                    url: `${hostName.getHost()}/auth/check`,
                    data: {
                        token: result.data.token,
                    },
                })
                if (result2.status === 200) {
                    if (result2.data.user.role === 'admin') {
                        if (localStorage.getItem('token')) {
                            localStorage.setItem('typeClient','dev')
                            localStorage.setItem('tokenDev', result.data.token)
                            localStorage.setItem('emailDev', result.data.email)

                        }
                        else if (localStorage.getItem('tokenDev')) {
                            localStorage.setItem('typeClient','prod')
                            localStorage.setItem('token', result.data.token)
                            localStorage.setItem('email', result.data.email)

                        }
                        localStorage.setItem('globalToken', result.data.token)

                        navigate("/");
                        dispatch(getUser(result2.data.user))
                        getAllUsers()
                        getAllProjects()
                        getAllProjectsNotApprove()
                        setIsShowModal(false)
                    }
                    else {
                        alert('permission denied')
                    }

                }
                dispatch(getStatusLoad(false))
                // toast.success('success')
                // getUserByToken(result.data.token, setIsLoad, dispatch, getPosts, setTasks)
                // router.push('/')
            } else {
                dispatch(getStatusLoad(false))
                // if (localStorage.getItem('token')) {
                //     localStorage.removeItem('token')
                //     localStorage.removeItem('email')
                // }
                // if (localStorage.getItem('tokenDev')) {
                //     localStorage.removeItem('token')
                //     localStorage.removeItem('email')
                // }
                // toast.error(result.data.message)
                // removeCookie('token')
                // removeCookie2('email')
            }
        }
    }
    const getAllUsers = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/user/all`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            dispatch(getUsers(result.data))
        }
    }

    const getAllProjects = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            dispatch(getProjects(result.data))
        }
    }

    const getAllProjectsNotApprove = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            dispatch(getNAProjects(result.data))
        }
    }
    const onChangeEmail = (e: any) => {
        setEmail(e.target.value)
    }

    const onChangePass = (e: any) => {
        setPass(e.target.value)
    }
    const onLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        localStorage.removeItem('tokenDev')
        localStorage.removeItem('emailDev')
        localStorage.removeItem('globalToken')
        window.location.replace('/signin')
    }



    const onClickLogin = () => {
        setIsShowModal(true)
    }

    const onClickBack = () => {
        setIsShowModal(false)
    }

    const onClickStopProp = (e: any) => {
        e.stopPropagation()
    }


    return (
        <DivWrapperLeftPanelSC>
            <DivBoxProfileInfoSC>
                <DivBoxLenteSC>
                    <Chip size="sm">v. 2.3</Chip>
                </DivBoxLenteSC>
                <Tooltip
                    placement={'right'}
                    content={
                    <div className="px-1 py-2">
                        <div className="text-small font-bold">{user?.email}</div>
                        <div className="text-tiny">{user?.userName}</div>
                        <div className="text-tiny">{user?.firstName} {user?.lastName}</div>
                    </div>
                }>
                    <User
                        avatarProps={{
                            src: `${hostName.getHost()}/static/profiles/${
                                user?.photo
                            }`,
                            isBordered: true,
                            color:"success",
                            // className:"w-20 h-20 text-large",
                            radius:"sm",
                            // size: "sm"
                        }}
                        description={user?.email && user?.email.length <= 16 ? user?.email : user?.email.substring(0, 13) + '...'}
                        name={user?.userName ? user.userName.length <= 16 ? user.userName :  user.userName.substring(0, 13)  + '...': ''}
                        isFocusable={true}
                    />
                </Tooltip>



                <ProfileSelect/>
            </DivBoxProfileInfoSC>
            <TabList/>
            <DivLogoutSc>
                <DivItemTempSC onClick={onLogout}>
                    <LuLogOut /> Logout
                </DivItemTempSC>
                {localStorage.getItem('token') && localStorage.getItem('tokenDev') ? <DivItemTempSC><FaRegCheckCircle /> 2 profiles</DivItemTempSC> :
                    (<DivItemTempSC onClick={onClickLogin}><IoPersonAddOutline/> Add profile</DivItemTempSC>)}
            </DivLogoutSc>
            {isShowModal && <DivBackLoginSC onClick={onClickBack}>
                <DivFormWrapperSC onClick={onClickStopProp} >
                    <SpanTextSC>{
                        localStorage.getItem('token') ? 'Dev login' : 'Prod login'

                    }</SpanTextSC>
                    <Input
                        type="email"
                        label="Email"
                        onChange={onChangeEmail}
                        size={'sm'}
                    />
                    <Input
                        type="password"
                        label="Passsword"
                        onChange={onChangePass}
                        size={'sm'}
                    />
                    <Button
                        color="success"
                        variant="bordered"
                        startContent={<SiGnuprivacyguard />}
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </DivFormWrapperSC>
            </DivBackLoginSC>}
        </DivWrapperLeftPanelSC>
    )
};

export default Panel;