import React, {useEffect} from 'react';
import {DivWrapperUsersSC} from "../styles/styles.users";
import BlockListUsers from "../components/users/block_list_users";
import BlockInfo from "../components/users/block_info";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {clearSelectedUser} from "../store/slices/usersSlice";

const Users = (props: any) => {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {
        selectedUsersInfo
    } = useAppSelector(state => (state.usersReducer))
    const dispatch = useAppDispatch()

    useEffect(() => {
        console.log('fdgsdfgdfsg', selectedUsersInfo)
        if (selectedUsersInfo) {
            onOpen()
        }
    }, [selectedUsersInfo]);

    const onChange = () => {
        dispatch(clearSelectedUser())
    }

    return (
        <DivWrapperUsersSC>
            <BlockListUsers/>
            <Modal
                isOpen={isOpen}
                scrollBehavior={'inside'}
                backdrop={'blur'}
                size={"5xl"}
                   onOpenChange={onOpenChange}
                onClose={onChange}
            >

                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Modal Title</ModalHeader>
                            <ModalBody>
                                <BlockInfo/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onClose}
                                >
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </DivWrapperUsersSC>
    )
};

export default Users;