import React from 'react';
import {DivWrapperDashboardSC} from "../styles/styles.dashboard";

const Dashboard = (props: any) => {
    return(
        <DivWrapperDashboardSC>

        </DivWrapperDashboardSC>
    )
};

export default Dashboard;