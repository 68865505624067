import React, {useState} from 'react';
import {
    DivBoxBodySC,
    DivBoxHeaderSC,
    DivDashListReportsSC,
    DivWrapperContentSC,
    DivWrapperReportsSC
} from "../styles/styles.reports";
import BodyReports from "../components/reports/body";
import {Header} from "../components/reports/header/Header";



const Reports = () => {
    const [numTab, setNumTab] = useState<number>(0)

    return (
        <DivWrapperReportsSC>
            <DivDashListReportsSC>
                <DivWrapperContentSC>
                    <DivBoxHeaderSC>
                        <Header
                            numTab={numTab}
                            setNum={setNumTab}
                        />
                    </DivBoxHeaderSC>
                    <DivBoxBodySC>
                        <BodyReports
                            numTab={numTab}
                        />
                    </DivBoxBodySC>
                </DivWrapperContentSC>
            </DivDashListReportsSC>
        </DivWrapperReportsSC>
    )
};

export default Reports;