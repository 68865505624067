import styled from "styled-components";

export const DivWrapperUsersSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    gap: 30px;
    box-sizing: border-box;
`

export const DivWrapperUsersListSC = styled.div`
    display: grid;
    border-radius: 30px;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    background: #202124;
    overflow: auto;
`

export const DivWrapperListUsersSC = styled.div`
    display: grid;
    width: 100%;
    height:100%;
    grid-template-columns: 100%;
    grid-auto-rows: 100%;
    padding: 20px;
    box-sizing: border-box;
    gap: 10px;
`

export const DivWrapperSmallBlockSC = styled.div`
    display: grid;
    border-radius: 20px;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    background: #202124;
    height: 100%;
    width: 100%;
    position: relative;
`

export const DivWrapperInfoUsersSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: repeat(14, max-content) auto;
    padding: 20px;
    box-sizing: border-box;
    gap: 10px;
`

export const DivWrapperDashSC = styled.div<{
    $isSelected: boolean
}>`
    display: grid;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    background: #36373a;
    grid-template-rows: 100%;
    grid-template-columns:repeat(14, max-content);
    padding: 15px;
    box-sizing: border-box;
    gap: 5px;
    box-shadow: ${({$isSelected}) => ($isSelected ? 'inset 0px 0px 4px 0px greenyellow' : 'none')} ;
`

export const DivAvatarWrapperSc = styled.div<{
    $src: string;
}>`
    display: grid;
    height: 100%;
    width: 50px;
    border-radius: 50%;
    background: url(${({$src}) => ($src)});
    background-size: cover;
`

export const DivDividerVerticalSC = styled.div`
    display: grid;
    height: 100%;
    width: 2px;
    background: #424346;
`

export const DivWrapperUserNameSC = styled.div`
    display: grid;
    height: 100%;
    width: 250px;
    color: white;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    align-items: center;
    overflow: hidden;
`

export const SpanUserNameSC = styled.span`
    display: grid;
    color: white;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content;
    gap: 5px;
`

export const SpanEmailSC = styled.span`
    display: grid;
    color: darkgray;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content;
    gap: 5px;
`

export const DivWrapperRoleSC = styled.div`
    display: grid;
    width: 150px;
    height: 100%;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 100%;
    justify-items: center;
    align-items: center;
    color: white;
`

export const DivWrapperDateSC = styled.div`
    display: grid;
    width: 250px;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
`

export const DivWrapperDateCreatedSC = styled.div`
    grid-template-rows: 100%;
    grid-template-columns: 1fr 2fr;
    display: grid;
    font-size: 15px;
    color: darkgray;
`

export const DivWrapperStatusVerifSC = styled.div`
    display: grid;
    width: 150px;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    color: darkgray;
    
`

export const DivWrapperIconsAndTextVerifSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    span {
        font-size: 12px;
    }
    
`

export const DivWrapperReputationSC = styled.div`
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 2fr 1fr;
    height: 100%;
    width: 150px;
    justify-items: center;
    align-items: center;
    color: darkgray;
`

export const ButtonMoreSC = styled.button`
    display: grid;
    border: none;
    width:50px;
    height: 100%;
    background: transparent;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    color: darkgray;
`

export const DivBoxAvatarNameSc = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-rows: max-content;
    grid-template-columns: 100%;
    gap: 30px;
    
`

export const DivBoxAvatarSC = styled.div<{
    $src: string;
}>`
    display: grid;
    width: 104px;
    height: 104px;
    background: url(${({$src}) => ($src)});
    background-size: cover;
    border-radius: 50%;
    
`

export const DivBoxInfoSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 100%;
    
`

export const DivBoxUserNameSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    color: white;
    //justify-items: center;
    align-items: center;
`

export const DivBoxUserEmailSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    color: darkgray;
    //justify-items: center;
    align-items: center;
`

export const DivDividerHorizontSC = styled.div`
    display: grid;
    width: 100%;
    height: 2px;
    background: #424346;
`

export const DivBoxAdminSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-rows: 100%;
    grid-template-columns: max-content 1fr;
    gap: 50px;
    align-items: center;
`

export const SpanAdminSC = styled.span`
    display: grid;
    color: darkgray;
`

export const ButtonCreateAdmin = styled.button<{
    $bgColor: string;
}>`
    display: grid;
    height: 30px;
    width: max-content;
    background: ${({$bgColor}) => ($bgColor)};
    border: none;
    border-radius: 10px;
    justify-items: center;
    align-items: center;
    color: white;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
`

export const DivBoxTextSc = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    color: #727070;
`

export const DivTempSC = styled.div`
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 10px;
`

export const DivBoxTabsSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 100%;
    gap: 20px;
    overflow: hidden;
`

export const DivWrapperTabsListSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 1fr;
`

export const DivTabSC = styled.div<{
    $isActive: boolean
}>`
    display: grid;
    width: 98%;
    height: 100%;
    justify-items: center;
    align-items: center;
    color: white;
    border-radius: 10px;
    border: 1px solid #727070;
    cursor: pointer;
    background: ${({$isActive}) => ($isActive ? '#0b8f58' : 'transparent')};
`

export const DivItemTabSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    overflow: auto;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
`

export const DivWrapperTabProjectsSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: 100%;
    grid-auto-rows: 80px;
    gap: 10px;
`
export const DivWrapperProjectNameSC = styled.div`
    display: grid;
    height: 100%;
    width: 150px;
    color: white;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    align-items: center;
    overflow: hidden;
`

export const DivWrapperDash2SC = styled.div<{
    $isSelected: boolean
}>`
    display: grid;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    background: #36373a;
    grid-template-rows: 100%;
    grid-template-columns:repeat(5, max-content);
    padding: 15px;
    box-sizing: border-box;
    gap: 20px;
    box-shadow: ${({$isSelected}) => ($isSelected ? 'inset 0px 0px 20px 0px greenyellow' : 'none')} ;
`

export const DivMoreButtonSC = styled.div`
    display: grid;
    cursor: pointer;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
    color: darkgray;
`

export const SpanDateSc = styled.span`
    display: grid;
    font-size: 12px;
    color: darkgray;
`

export const DivButtonCloseSC = styled.div`
    display: grid;
    position: absolute;
    top: 20px;
    right: 20px;
    width: max-content;
    height: max-content;
    cursor: pointer;
`