import React, {useEffect, useState} from 'react';
import {
    ButtonSC,
    DivFormWrapperSC,
    DivTabsSC,
    DivWrapperLoginSC,
    DivWrapperTabsSC,
    InputFormSC
} from "../styles/styles.signin";
import {axiosGet, axiosPost} from "../api/fetch";
import hostName from "../api/hostName";
import { useNavigate } from "react-router-dom";
import {useAppDispatch} from "../store/hooks";
import {getStatusLoad, getUser} from "../store/slices/userSlice,";
import {getUsers} from "../store/slices/usersSlice";
import {getNAProjects, getProjects} from "../store/slices/projectsSlice";
import {Input} from "@nextui-org/react";
import {Button} from "@nextui-org/react";
import { PiSignInBold } from "react-icons/pi";


const Signin = (props: any) => {
    const [email, setEmail] = useState<string>('')
    const [pass, setPass] = useState<string>('')
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [numActiveButton, setNumActiveButton] = useState<number>(0)

    useEffect(() => {
        localStorage.setItem('typeClient', numActiveButton === 0 ? 'prod' : 'dev')
    }, []);
    const onSubmit = async () => {
        if (email && pass) {


            dispatch(getStatusLoad(true))
            const result = await axiosPost({
                url: `${hostName.getHost()}/auth/signin`,
                data: {
                    email: email,
                    password: pass,
                },
            })
            console.log(result.data.message)
            if (result.status === 200) {


                const result2 = await axiosPost({
                    url: `${hostName.getHost()}/auth/check`,
                    data: {
                        token: result.data.token,
                    },
                })
                console.log('>>>>>>>>>>>>>>><<<><', result2)
                if (result2.status === 200) {
                    if (result2.data.user.role === 'admin') {
                        if (localStorage.getItem('typeClient') === 'prod') {
                            localStorage.setItem('token', result.data.token)
                            localStorage.setItem('email', result.data.email)

                        }
                        else {
                            localStorage.setItem('tokenDev', result.data.token)
                            localStorage.setItem('emailDev', result.data.email)
                        }
                        localStorage.setItem('globalToken', result.data.token)
                        navigate("/");
                        dispatch(getUser(result2.data.user))
                        getAllUsers()
                        getAllProjects()
                        getAllProjectsNotApprove()
                    }
                    else {
                        alert('permission denied')
                    }

                }
                dispatch(getStatusLoad(false))
                // toast.success('success')
                // getUserByToken(result.data.token, setIsLoad, dispatch, getPosts, setTasks)
                // router.push('/')
            } else {
                dispatch(getStatusLoad(false))

                if (localStorage.getItem('typeClient') === 'prod') {
                    localStorage.removeItem('token')
                    localStorage.removeItem('email')

                }
                else {
                    localStorage.removeItem('tokenDev')
                    localStorage.removeItem('emailDev')
                }
                // toast.error(result.data.message)
                // removeCookie('token')
                // removeCookie2('email')
            }
        }
    }
    const getAllUsers = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/user/all`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            console.log('><><>>><><>>><', result.data)
            dispatch(getUsers(result.data))
        }
    }

    const getAllProjects = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getProjects(result.data))
        }
    }

    const getAllProjectsNotApprove = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getNAProjects(result.data))
        }
    }
    const onChangeEmail = (e: any) => {
        setEmail(e.target.value)
    }

    const onChangePass = (e: any) => {
        setPass(e.target.value)
    }

    const onClickType = (num: number) => () => {
        setNumActiveButton(num)
        localStorage.setItem('typeClient', num === 0 ? 'prod' : 'dev')
        hostName.updateHost(num === 0 ? hostName.staticHost : hostName.staticDevHost)
    }

    return (
        <DivWrapperLoginSC>
            <DivFormWrapperSC>
                <DivWrapperTabsSC>
                    <DivTabsSC
                        $isActive={numActiveButton === 0}
                        onClick={onClickType(0)}
                    >Prod</DivTabsSC>
                    <DivTabsSC
                        $isActive={numActiveButton === 1}
                        onClick={onClickType(1)}
                    >Dev</DivTabsSC>
                </DivWrapperTabsSC>
                {/*<InputFormSC*/}
                {/*    placeholder={'Email'}*/}
                {/*    type={'text'}*/}
                {/*    onChange={onChangeEmail}*/}
                {/*/>*/}
                <Input
                    type="email"
                    label="Email"
                    onChange={onChangeEmail}
                    size={'sm'}
                />
                <Input
                    type="password"
                    label="Passsword"
                    onChange={onChangePass}
                    size={'sm'}
                />
                {/*<InputFormSC*/}
                {/*    placeholder={'Password'}*/}
                {/*    onChange={onChangePass}*/}
                {/*    type={'password'}/>*/}
                <Button
                    color="success"
                    variant="bordered"
                    startContent={<PiSignInBold />}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
                {/*<ButtonSC*/}
                {/*    onClick={onSubmit}*/}
                {/*>*/}
                {/*    Submit*/}
                {/*</ButtonSC>*/}
            </DivFormWrapperSC>
        </DivWrapperLoginSC>
    )
};

export default Signin;