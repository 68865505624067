import React, {useCallback, useState} from 'react';
import {DivDashListProjectslistSC, DivDashListProjectsSC} from "../../styles/styles.projects";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import hostName from "../../api/hostName";
import {IProject, selectProject} from "../../store/slices/projectsSlice";
import {axiosDelete, axiosGet} from "../../api/fetch";
import {getStatusLoad} from "../../store/slices/userSlice,";
import {getSelectedProjectUser} from "../../store/slices/usersSlice";
import ItemProjectInfo from "./item_project_info";
import {IInitiative, selectInitiative} from "../../store/slices/InitiativesSlice";

import {DateTime} from "luxon";
import {
    Button,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tabs,
    User
} from "@nextui-org/react";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";

import Tools from "../../tools";



const columns = [
    { uid: "projectInfo", name: "Project" },
    { uid: "created", name: "Created" },
    { uid: "owner", name: "Owner" },
    { uid: "subscribers", name: "Subscribers" },
    { uid: "actions", name: "Actions" },
];
const Index = () => {
    const [numTabs, setNumTabs] = useState<number>(0)
    const [numTabsType, setNumTabsType] = useState<number>(0)
    const {
        projects,
        notAppProjects
    } = useAppSelector(state => (state.projectsReducer))
    const {
        initiative,
        notAppInitiative
    } = useAppSelector(state => (state.initiativesReducer))
    const {
        selectedProjectsUser
    } = useAppSelector(state => (state.usersReducer))
    const dispatch = useAppDispatch()
    // const queryClient = useQueryClient()




    const getUserInfo = (item: IProject | IInitiative) => async () => {
        dispatch(getStatusLoad(true))
        const result2 = await axiosGet({
            url: `${hostName.getHost()}/user/${item.owner.id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        dispatch(getStatusLoad(false))
        if (result2.status === 200) {
            dispatch(getSelectedProjectUser(result2.data))
            if (item.type === 'project') {
                dispatch(selectProject(item))
                setNumTabsType(0)
            }
            else {
                dispatch(selectInitiative(item))
                setNumTabsType(1)
            }

        }

    }

    const deleteProject = (item: IProject | IInitiative) => async () => {
        dispatch(getStatusLoad(true))
        const result2 = await axiosDelete({
            url: `${hostName.getHost()}/admin/del-pr/${item.id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        dispatch(getStatusLoad(false))
        if (result2.status === 200) {
            Tools.getAllRequestProjects(dispatch)
        }

    }



    const renderCell = useCallback((item: IProject | IInitiative, columnKey: any) => {
        // const cellValue = user[columnKey];

        switch (columnKey) {
            case "projectInfo":
                return (
                    <User
                        avatarProps={
                        {
                            radius: "lg",
                            src: `${hostName.getHost()}/static/projects/${item.id}/${item.projectPhoto}`
                        }
                        }
                        // description={item.user.email}
                        name={item.projectName.length > 20 ? item.projectName.substr(0, 20) : item.projectName}
                    >
                        {item.projectName.length > 20 ? item.projectName.substr(0, 20) : item.projectName}
                    </User>
                );
            case "created":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.createdAt).toFormat('ff')
                        }
                    </div>
                );
            case "owner":
                return (
                    <User
                        avatarProps={
                            {
                                radius: "lg",
                                src: `${hostName.getHost()}/static/profiles/${item.owner.photo}`
                            }
                        }
                        // description={item.user.email}
                        name={item.owner.userName.length > 20 ? item.owner.userName.substr(0, 20) : item.owner.userName}
                    >
                        {item.owner.userName.length > 20 ? item.owner.userName.substr(0, 20) : item.owner.userName}
                    </User>
                );
            case "subscribers":
                return (
                    <div className="flex flex-col">
                        {
                            item.subscribers ? item.subscribers.length : 0
                        }
                    </div>
                );
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        <Button
                            color="success"
                            // icon={<PresenceBlocked24Regular />}
                            onClick={getUserInfo(item)}
                            isIconOnly
                            variant="light"

                        >
                            <FiMoreHorizontal />
                        </Button>
                        <Button
                            color="danger"
                            // icon={<PresenceBlocked24Regular />}
                            onClick={deleteProject(item)}
                            isIconOnly
                            variant="light"
                        >
                            <MdOutlineDeleteOutline
                                size={20}
                            />
                        </Button>
                    </div>
                );
            default:
                return ;
        }
    }, []);

    return(
        <DivDashListProjectsSC>
            {
                selectedProjectsUser ?
                    <ItemProjectInfo
                        numTabsType={numTabsType}
                    />
                    :
                    <DivDashListProjectslistSC>
                        <Tabs aria-label="Options">
                            <Tab key="tab1" title="Approve">
                                <Tabs aria-label="Options">
                                    <Tab key="tab1" title="Projects">
                                        <Table
                                            isHeaderSticky={true}
                                            isStriped
                                            aria-label="Example table with custom cells">
                                            <TableHeader columns={columns}>
                                                {(column) => (
                                                    <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                                                        {column.name}
                                                    </TableColumn>
                                                )}
                                            </TableHeader>
                                            <TableBody items={projects}>
                                                {(item) => (
                                                    <TableRow key={item.id}>
                                                        {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Tab>
                                    <Tab key="tab2" title="Initiative">
                                        <Table
                                            isHeaderSticky={true}
                                            aria-label="Example table with custom cells"
                                            isStriped
                                        >
                                            <TableHeader columns={columns}>
                                                {(column) => (
                                                    <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                                                        {column.name}
                                                    </TableColumn>
                                                )}
                                            </TableHeader>
                                            <TableBody items={initiative}>
                                                {(item) => (
                                                    <TableRow key={item.id}>
                                                        {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Tab>
                                </Tabs>
                            </Tab>
                            <Tab key="tab2" title="Not approve">
                                <Tabs aria-label="Options">
                                    <Tab key="tab1" title="Projects">
                                        <Table
                                            isHeaderSticky={true}
                                            aria-label="Example table with custom cells"
                                            isStriped
                                        >
                                            <TableHeader columns={columns}>
                                                {(column) => (
                                                    <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                                                        {column.name}
                                                    </TableColumn>
                                                )}
                                            </TableHeader>
                                            <TableBody items={notAppProjects}>
                                                {(item) => (
                                                    <TableRow key={item.id}>
                                                        {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Tab>
                                    <Tab key="tab2" title="Initiative">
                                        <Table
                                            isStriped
                                            isHeaderSticky={true}
                                            aria-label="Example table with custom cells"
                                        >
                                            <TableHeader columns={columns}>
                                                {(column) => (
                                                    <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                                                        {column.name}
                                                    </TableColumn>
                                                )}
                                            </TableHeader>
                                            <TableBody items={notAppInitiative}>
                                                {(item) => (
                                                    <TableRow key={item.id}>
                                                        {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Tab>
                                </Tabs>
                            </Tab>
                        </Tabs>



                    </DivDashListProjectslistSC>
            }

        </DivDashListProjectsSC>
    )
};

export default Index;