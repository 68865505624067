import styled from "styled-components";
import {Link} from "react-router-dom";

export const DivWrapperLeftPanelSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: max-content 1fr 50px;
    padding: 30px;
    box-sizing: border-box;
    gap: 30px;
`

export const DivBoxProfileInfoSC = styled.div`
    display: grid;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: 100%;
    background: #202124;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    justify-items: center;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px;
    overflow: hidden;
    position: relative;
`

export const DivLogoBoxSC = styled.div<{
    $src: string | null;
}>`
    display: grid;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid forestgreen;
    background: url(${({$src}) => ($src ? $src : null)});
    background-size: cover;
`

export const H4SC = styled.h4`
    color: white;
    padding: 0;
    margin: 0;
`

export const SpanSC = styled.span`
    color: white;
`

export const DivWrapperTabListSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    background: #202124;
    border-radius: 20px;
    justify-items: center;
    padding: 30px 0;
    box-sizing: border-box;
    grid-template-columns: 100%;
    grid-auto-rows: 40px;
`

export const DivTabSC = styled(Link)<{
    $isActive: boolean;
}>`
    display: grid;
    background: ${({$isActive}) => ($isActive ? 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,95,62,1) 0%, rgba(0,0,0,0) 100%)' : 'rgba(0,0,0,0)')};
    width: 100%;
    height: 100%;
    border-left: ${({$isActive}) => ($isActive ? '2px solid #08a563' : '2px solid rgba(0,0,0,0)')};
    color: white;
    text-decoration: none;
    padding-left: 30px;
    box-sizing: border-box;
    align-items: center;
`

export const DivWrapperSelectSC = styled.div`
    display: grid;
    width: 100%;
`
export const SelectWrapperSC = styled.select`
    background: #161616;
    display: grid;
    width: 100%;
    border-radius: 12px;
    height: 40px;
    border: none;
    color: white;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
`

export const DivBoxLenteSC = styled.div`
    display: grid;
    position: absolute;
    //background: rgb(71 71 71);
    //width: 25px;
    //height: 40px;
    top: 0;
    right: 10px;
    overflow: hidden;
    color: darkgray;
    justify-items: center;
    padding-top: 5px;
    box-sizing: border-box;
`

export const DivAngleLenteSC = styled.div`
    display: grid;
    width: 100%;
    height: 25px;
    transform: rotate(45deg);
    background: #202124;
`