import styled from "styled-components";

export const DivWrapperReportsSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    box-sizing: border-box;
`

export const DivDashListReportsSC = styled.div`
    display: grid;
    border-radius: 30px;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    background: #202124;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    
`

export const DivWrapperContentSC = styled.div`
    display: grid;
    width: 100%;
    height:100%;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 100%;
    gap: 20px;
`

export const DivBoxHeaderSC = styled.div`
    display: grid;
    width: 100%;
    height: 30px;
`

export const DivBoxBodySC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    overflow: auto;
`

export const DivBoxHeaderContentSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    gap: 8px;
    grid-template-rows: 100%;
    grid-template-columns: repeat(5, max-content);
    
`

export const DivWrapperBodyContentSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    gap: 10px;
    
`

export const ButtonTabsHeaderSC = styled.button<{
    $isActive: boolean
}>`
    display: grid;
    width: max-content;
    height: 100%;
    background: ${({$isActive}) => ($isActive ? '#0b8f58' : '#161616')} ;
    border: 1px solid #727070;
    justify-items: center;
    align-items: center;
    border-radius: 90px;
    padding-right: 15px;
    padding-left: 15px;
    color: white;
`

export const DivItemReportSC = styled.div<{
    $height: number;
}>`
    display: grid;
    height: ${({$height}) => ($height)}px;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    border: 1px;
    justify: space-between;
    background: #36373a;
    box-sizing: border-box;
    
    grid-template-columns: 1.2fr 1fr 1.2fr;
    grid-template-rows: 100%;
    gap: 8px;
`

export const DivWrapperColumnItemReportSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-auto-rows: 100%;
    justify-items: center;
    align-items: center;
`

export const DivWrapperFirstColumnsReportSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: max-content 1fr max-content;
    grid-template-columns: 100%;
    gap: 10px;
`

export const DivWrapperLastsColumnsReportSC = styled.div`
    display: grid;
    width: 100%;
    //height: max-content;
    
`

export const DivWrapperImagesReportSC = styled.div`
    display: grid;
    width: 100%;
    height: 33px;
    grid-template-columns: repeat(auto-fill, 33px);
    grid-template-rows: 100%;
    gap: 8px;
`

export const DivImageReportSC = styled.div<{
    $src: string;
}>`
    display: grid;
    width: 50px;
    height: 40px;
    background: url(${({$src}) => ($src ? $src : 'linear-gradient(22.89deg, #033413 14.43%, #25E561 32.02%, #F0F04F 65.4%)')});
    background-size: contain;
    border-radius: 6px;
    background-repeat: no-repeat;
    cursor: pointer;
`

export const DivBoxTextBoldSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.06em;
    text-align: left;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

export const DivBoxTextDefaultSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
`

export const SpanTextSC = styled.span`
    display: grid;
    color: darkgray;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.06em;
    text-align: left;

`

export const DivWrapperUserInfoSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-rows: 100%;
    grid-template-columns: repeat(7, max-content);
    justify-items: center;
    align-items: center;
    gap: 8px;
    
`

export const DIvWrapperImageAvatarSC = styled.div<{
    $src?: string;
}>`
    display: grid;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: url(${({$src}) => ($src ? $src : 'linear-gradient(22.89deg, #033413 14.43%, #25E561 32.02%, #F0F04F 65.4%)')});
    background-size: contain;
`

export const DivDotSC = styled.div<{
    $bgColor:string;
}>`
    display: grid;
    width: 2px;
    height: 2px;
    background: ${({$bgColor}) => ($bgColor)};
`

export const DivWrapperButtonsReportSC = styled.div`
    display: grid;
    width: 100%;
    height: 40px;
    grid-template-rows: 100%;
    grid-template-columns: 1fr repeat(4, max-content);
    gap: 8px;
`
export const ButtonReportSC = styled.button<{
    $disabled: boolean
}>`
    display: grid;
    width: max-content;
    height: 40px;
    border-radius: 12px;
    //padding: 16px;
    border: 1px solid #202124;
    background: transparent;
    justify-items: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    color: ${({$disabled}) => ($disabled ? 'darkgrey' : 'white')};
    cursor: ${({$disabled}) => ($disabled ? 'default' : 'pointer')};
`

export const DivReportEventBoxImageSC = styled.div`
    display: grid;
    width: 77px;
    height: 43px;
`

export const DivBoxUserINfoSC =styled.div`
    display: grid;
    width: 100%;
    height: 30px;
    grid-template-rows: 100%;
    grid-template-columns: repeat(4, max-content);
    gap: 8px;
    align-items: center;
`

export const DivBoxImageUserSC = styled.div<{
    $src?: string;
}>`
    display: grid;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: url(${({$src}) => ($src ? $src : 'linear-gradient(22.89deg, #033413 14.43%, #25E561 32.02%, #F0F04F 65.4%)')});
    background-size: contain;
`

export const DivTempWiSC = styled.div`
    display: grid;
    width: 100%;
`