import React from 'react';
import {
    AWrapperSC,
    ButtonApproveSC,
    ButtonBackSC,
    DivBoxCheckSC,
    DivBoxColumnInfoProjectsSC,
    DivBoxColumnSC,
    DivBoxDashSC,
    DivBoxProjectInfoSC,
    DivButtonBackSC,
    DivContentColumnsSC,
    DivPhotoBoxSC,
    DivTitleCellSC,
    DivWrapperListTagsSC,
    H4SC,
    ItemInfoCellSC,
    TitleColumnSC
} from "../../styles/styles.projects";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import { MdOutlineArrowBackIos } from "react-icons/md";
import {clearSelectedProjectUser} from "../../store/slices/usersSlice";
import hostName from "../../api/hostName";
import { FaCheck } from "react-icons/fa";
import { AiOutlineStop } from "react-icons/ai";
import {DateTime} from "luxon";
import {axiosGet, axiosPatch} from "../../api/fetch";
import {getStatusLoad} from "../../store/slices/userSlice,";
import {getNAProjects, getProjects, IProject} from "../../store/slices/projectsSlice";
import {getInitiatives, getNAInitiatives, IInitiative} from "../../store/slices/InitiativesSlice";
import { Chip} from "@nextui-org/react";

interface IProps {
    numTabsType: number;

}

const ItemProjectInfo = (props: IProps) => {
    const {
        numTabsType
    } = props

    const {
        selectedProjectsUser
    } = useAppSelector(state => (state.usersReducer))
    const {
        selectedProject
    } = useAppSelector(state => (state.projectsReducer))
    const {
        selectedInitiative
    } = useAppSelector(state => state.initiativesReducer)
    const {
        user
    } = useAppSelector(state => (state.userReducer))
    const dispatch = useAppDispatch()



    const onBack = () => {
        dispatch(clearSelectedProjectUser())
    }

    const onClick = (object: IProject | IInitiative) => async () => {
        dispatch(getStatusLoad(true))
        console.log('><><><>><')
        const result = await axiosPatch({
            url: `${hostName.getHost()}/admin/ap-pr`,
            // url: `http://localhost:8083/auth/signup`,
            data: {
                projectId: object!.id,
                adminId: user!.id
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        dispatch(getStatusLoad(false))
        if (result.status === 201) {
            // getUserInfo(user.id)
            console.log('><><><>><', result)
            getAllProjects()
            getAllProjectsNotApprove()
            getAllInitiatives()
            getAllInitiativesNotApprove()


        }
        else {
            alert(result.data.message)
        }

    }

    const getAllProjects = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getProjects(result.data))
        }
    }

    const getAllProjectsNotApprove = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getNAProjects(result.data))
        }
    }

    const getAllInitiatives = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getInitiatives(result.data))
        }
    }

    const getAllInitiativesNotApprove = async () => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getNAInitiatives(result.data))
        }
    }

    let selectedObject:IProject | IInitiative

    if (numTabsType === 0) {
        selectedObject = selectedProject!
    }
    else {
        selectedObject = selectedInitiative!
    }

    return(
        <DivBoxProjectInfoSC>
            <DivButtonBackSC>
                <ButtonBackSC onClick={onBack}>
                    <MdOutlineArrowBackIos />
                </ButtonBackSC>
                <H4SC>{selectedObject?.projectName}</H4SC>
            </DivButtonBackSC>

            <DivBoxColumnInfoProjectsSC>

                <DivBoxColumnSC>
                    <DivContentColumnsSC>
                        {/*<DivDividerHorizontSC/>*/}
                        <TitleColumnSC>
                            {numTabsType === 0 ? 'Project' : 'Initiative'}
                        </TitleColumnSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project description
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedObject?.projectDescription}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>

                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project web site
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <AWrapperSC href={selectedObject?.websiteUrl} target={'_blank'}>{selectedObject?.websiteUrl}</AWrapperSC>
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project email
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedObject?.email}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project other information
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedObject?.otherInformation}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project phone number
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedObject?.phoneNumber}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project photo
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <DivPhotoBoxSC
                                    $image={`${hostName.getHost()}/static/projects/${selectedObject?.id}/${
                                        selectedObject?.projectPhoto
                                    }`}
                                />
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project approved
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <DivBoxCheckSC>
                                    {
                                        selectedObject?.approved ? <FaCheck color={'green'} /> :  <AiOutlineStop color={'red'}/>
                                    }
                                    {
                                        selectedObject?.approved ? <ButtonApproveSC
                                            onClick={onClick(selectedObject)}
                                            $color={'red'}
                                        >
                                            Delete approve
                                        </ButtonApproveSC> :  <ButtonApproveSC
                                            $color={'#207904'}
                                            onClick={onClick(selectedObject)}
                                        >
                                            Approve
                                        </ButtonApproveSC>
                                    }
                                </DivBoxCheckSC>

                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project created
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                { selectedObject &&  DateTime.fromISO(selectedObject?.createdAt).toFormat('ff')}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project updated
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                { selectedObject &&  DateTime.fromISO(selectedObject?.updatedAt).toFormat('ff')}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project tags
                            </DivTitleCellSC>
                            <DivWrapperListTagsSC>
                                {
                                    selectedObject?.tags.map((item, i) => {
                                        return(
                                            <Chip key={`asdfbbfvad${i}`} color="success" variant="shadow">{item.tag}</Chip>

                                        )
                                    })
                                }
                            </DivWrapperListTagsSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Current funding status
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedObject?.currentFundingStatus}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Ein number
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedObject?.einNumber}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Forecasts For Two Years
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <AWrapperSC href={`${hostName.getHost()}/static/projects/${selectedObject?.id}/${
                                    selectedObject?.forecastsForTwoYears
                                }`} target={'_blank'}>{selectedObject?.forecastsForTwoYears}</AWrapperSC>
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Incorporation Documents
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {
                                    selectedObject?.incorporationDocuments.map((item, i) => {
                                        return(
                                            <AWrapperSC href={`${hostName.getHost()}/static/projects/${selectedObject?.id}/${
                                               item
                                            }`} target={'_blank'}>{item}</AWrapperSC>
                                        )
                                    })
                                }

                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Project Roadmap
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <AWrapperSC href={`${hostName.getHost()}/static/projects/${selectedObject?.id}/${
                                    selectedObject?.projectRoadmap
                                }`} target={'_blank'}>{selectedObject?.projectRoadmap}</AWrapperSC>
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Promotional Materials
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {
                                    selectedObject?.promotionalMaterials.map((item, i) => {
                                        return(
                                            <AWrapperSC href={`${hostName.getHost()}/static/projects/${selectedObject?.id}/${
                                                item
                                            }`} target={'_blank'}>{item}</AWrapperSC>
                                        )
                                    })
                                }
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                    </DivContentColumnsSC>

                </DivBoxColumnSC>
                <DivBoxColumnSC>
                    <DivContentColumnsSC>
                        <TitleColumnSC>
                            Owner
                        </TitleColumnSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User name
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedProjectsUser?.userName}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User photo
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <DivPhotoBoxSC
                                    $image={`${hostName.getHost()}/static/profiles/${
                                        selectedProjectsUser?.photo
                                    }`}
                                />
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User email
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedProjectsUser?.email}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Full name
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {`${selectedProjectsUser?.firstName} ${selectedProjectsUser?.lastName}`}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User bio
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedProjectsUser?.bio}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Profile created
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                { selectedProjectsUser &&  DateTime.fromISO(selectedProjectsUser?.createdAt).toFormat('ff')}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                Profile updated
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                { selectedProjectsUser &&  DateTime.fromISO(selectedProjectsUser?.updatedAt).toFormat('ff')}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User reputation score
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedProjectsUser?.reputationScore}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User role
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                {selectedProjectsUser?.role}
                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User email verificated
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <DivBoxCheckSC>
                                    {
                                        selectedProjectsUser?.isEmailVereficated ? <FaCheck color={'green'} /> :  <AiOutlineStop color={'red'}/>
                                    }

                                </DivBoxCheckSC>

                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User verified
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <DivBoxCheckSC>
                                    {
                                        selectedProjectsUser?.isVerified ? <FaCheck color={'green'} /> :  <AiOutlineStop color={'red'}/>
                                    }

                                </DivBoxCheckSC>

                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                        <ItemInfoCellSC>
                            <DivTitleCellSC>
                                User professional
                            </DivTitleCellSC>
                            <DivBoxDashSC>
                                <DivBoxCheckSC>
                                    {
                                        selectedProjectsUser?.isProfessional ? <FaCheck color={'green'} /> :  <AiOutlineStop color={'red'}/>
                                    }

                                </DivBoxCheckSC>

                            </DivBoxDashSC>
                        </ItemInfoCellSC>
                    </DivContentColumnsSC>
                </DivBoxColumnSC>
            </DivBoxColumnInfoProjectsSC>
        </DivBoxProjectInfoSC>
    )
};

export default ItemProjectInfo;