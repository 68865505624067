import {DivTemSC} from "../../styles/styles.blocks";

import hostName from "../../api/hostName";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import Tools from "../../tools";
import {DateTime} from "luxon";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User, Chip, Tooltip, getKeyValue} from "@nextui-org/react";
import {IBlock} from "../../store/slices/blocksSlice";
import {useCallback} from "react";
import {Button} from "@nextui-org/react";

const columns = [
    { uid: "user", name: "User blocked" },
    { uid: "created at", name: "Created block" },
    { uid: "message", name: "Message" },
    { uid: "actions", name: "Actions" },
];

export const BodyUnblock = () => {
    const {
        unblockUsers
    } = useAppSelector(state => state.blocksReducer)
    const dispatch = useAppDispatch()
    const onCLickUserBan = (id: string) => () => {
        Tools.changeBanUser(dispatch, id)
    }

    const renderCell = useCallback((item: IBlock, columnKey: any) => {
        // const cellValue = user[columnKey];

        switch (columnKey) {
            case "user":
                return (
                    <User
                        avatarProps={{radius: "lg", src: `${hostName.getHost()}/static/profiles/${item.user.photo}`}}
                        description={item.user.email}
                        name={item.user.userName}
                    >
                        {item.user.email}
                    </User>
                );
            case "created at":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.createdAt).toFormat('ff')
                        }
                    </div>
                );
            case "message":
                return (
                    <div className="flex flex-col">
                        {
                            item.messageFromUser
                        }
                    </div>
                );
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        <Button
                            color="danger"
                            // icon={<PresenceBlocked24Regular />}
                            onClick={onCLickUserBan(item.user.id)}
                        >
                            Block user
                        </Button>
                    </div>
                );
            default:
                return ;
        }
    }, []);
    return (
        <DivTemSC>
            <Table isStriped aria-label="Example table with custom cells">
                <TableHeader columns={columns}>
                    {(column) => (
                        <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody items={unblockUsers}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </DivTemSC>

    );
};