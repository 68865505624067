import React, {useState} from 'react';
import {DivBoxTabsSC, DivItemTabSC, DivTabSC, DivWrapperTabsListSC} from "../../styles/styles.users";
import ProjectsTab from "./projects_tab";
import PostsTabs from "./posts_tabs";
import {useStylesTabs} from "../../tools";
import {Tabs, Tab, Card, CardBody} from "@nextui-org/react";

const Tabs2 = (props:any) => {
    const [numTabs, setNumTabs] = useState<number>(0)
    const styles = useStylesTabs()
    const onClickTab = (num: number) => (e: any) => {
        setNumTabs(num)
    }

    const onSelectTabs = (e: any, p:any) => {
        setNumTabs(+p.value)
    }

    return (
        <DivBoxTabsSC>
            <DivWrapperTabsListSC>
                <div className="flex w-full flex-col">
                    <Tabs aria-label="Options">
                        <Tab key="Project" title="Projects">
                            <DivItemTabSC>
                                <ProjectsTab/>
                            </DivItemTabSC>
                        </Tab>
                        <Tab key="Post" title="Posts">
                            <DivItemTabSC>
                                <PostsTabs/>
                            </DivItemTabSC>
                        </Tab>

                    </Tabs>
                </div>

                    {/*<DivTabSC*/}
                    {/*    $isActive={numTabs === 0}*/}
                    {/*    onClick={onClickTab(0)}*/}
                    {/*>Projects</DivTabSC>*/}
                    {/*<DivTabSC*/}
                    {/*    onClick={onClickTab(1)}*/}
                    {/*    $isActive={numTabs === 1}*/}
                    {/*>Posts</DivTabSC>*/}
            </DivWrapperTabsListSC>
            {/*{*/}
            {/*    numTabs === 0 ?*/}
            {/*        <DivItemTabSC>*/}
            {/*            <ProjectsTab/>*/}
            {/*        </DivItemTabSC>*/}
            {/*        :*/}
            {/*        <DivItemTabSC>*/}
            {/*            <PostsTabs/>*/}
            {/*        </DivItemTabSC>*/}
            {/*}*/}
        </DivBoxTabsSC>
    )
};

export default Tabs2;