import React, {useCallback, useState, ReactNode, useEffect} from 'react';
import {DivImageReportSC, DivWrapperBodyContentSC} from "../../../styles/styles.reports";
import renderCellPosts from "./itemRepostPost";
import renderCellTasks from "./ItemReportTask";
import renderCellEvents from "./ItemReportEvent";
import renderCellUsers from "./ItemReportUser";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import renderCellProjects from "./ItemReportProject";
import {Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User} from "@nextui-org/react";
import {IUser} from "../../../store/slices/usersSlice";
import hostName from "../../../api/hostName";
import {DateTime} from "luxon";
import {GrUserAdmin} from "react-icons/gr";
import {FaUserSecret} from "react-icons/fa";
import {FaEarthEurope} from "react-icons/fa6";
import {MdAlternateEmail, MdDomainVerification, MdDoNotDisturb} from "react-icons/md";
import {FiMoreHorizontal} from "react-icons/fi";
import {IReport} from "../../../store/slices/reportSlice";
import HostName from "../../../api/hostName";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Image} from "@nextui-org/react";
import Tools from "../../../tools";


interface BodyReportsProps {
    numTab: number
}

const arrayReportPost: number[] = [
    1,2,3,4
]

const arrayReportTasks: number[] = [
    1,2,3,4
]

const arrayReportEvents: number[] = [
    1,2,3,4
]

const arrayReportUsers: number[] = [
    1,2,3,4
]

const columns = [
    { uid: "imagePost", name: "Image report" },
    { uid: "owner", name: "Owner" },
    { uid: "created", name: "Created" },
    { uid: "postText", name: "Post text" },
    { uid: "user", name: "User reported" },
    { uid: "message", name: "Message" },
    { uid: "updated", name: "Updated" },
    { uid: "actions", name: "Actions" },
];

const columnsProjects = [
    { uid: "imagePost", name: "Image report" },
    { uid: "projectName", name: "Project name" },
    { uid: "owner", name: "Owner" },
    { uid: "created", name: "Created" },
    { uid: "user", name: "User reported" },
    { uid: "message", name: "Message" },
    { uid: "updated", name: "Updated" },
    { uid: "actions", name: "Actions" },
];

const columnsTasks = [
    { uid: "imagePost", name: "Image report" },
    { uid: "owner", name: "Owner" },
    { uid: "created", name: "Created" },
    { uid: "user", name: "User reported" },
    { uid: "message", name: "Message" },
    { uid: "updated", name: "Updated" },
    { uid: "actions", name: "Actions" },
];

interface ITemp {
    columns: {
        uid: string;
        name: string;
    }[];
    items: IReport[];
    func: (
        item: IReport,
        columnKey: any,
        onClickImage: (url: string) => () => void,
        changeBlocked: (id: string) => () => void,
        isBlocked: boolean,
        deleteReport: (id: string) => () => void
    ) => ReactNode;
}

let dataByIndex: Array<ITemp> = []


const BodyReports = ({numTab}: BodyReportsProps) => {
    const {
        userReports,
        postReports,
        taskReports,
        eventReports,
        projectReports
    } = useAppSelector(state => state.reportsReducer)
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [image, setImage] = useState<string>('')
    const {
        users
    } = useAppSelector(state => state.usersReducer)





    dataByIndex = [
        {
            columns: columns,
            items: postReports,
            func: renderCellPosts
        },
        {
            columns: columnsTasks,
            items: taskReports,
            func: renderCellTasks
        },
        {
            columns: columnsTasks,
            items: eventReports,
            func: renderCellEvents
        },
        {
            columns: columnsTasks,
            items: userReports,
            func: renderCellUsers
        },
        {
            columns: columnsProjects,
            items: projectReports,
            func: renderCellProjects
        },

    ]
    const dispatch = useAppDispatch()

    const onClickImage = (src: string) => () => {
        setImage(src)
        onOpen()
    }

    const changeBlocked = (id: string) => () => {
        Tools.changeBanUser(dispatch, id)
    }



    const deleteReport = (id: string) => () => {
        Tools.deleteReport(dispatch, id)
    }



    const bodyRender = (item: IReport) => {
        let status = false
        if (users.length > 0) {
            const one = users.find((item2) => {
                if (item.accused) {
                    return item2.id === item.accused?.id
                }
                else if (item.task) {
                    return item2.id === item.task.project.owner.id
                }
                else if (item.event) {
                    return item2.id === item.event.project.owner.id
                }
                else if (item.project) {
                    return item2.id === item.project.owner.id
                }
                else if (item.post) {
                    return item2.id === item.post.user.id
                }

            })
            console.log('asfasffwfasfas', one?.isBlocked)
            status = one!.isBlocked
        }

        return (
            <TableRow key={item.id}>
                {(columnKey) =>
                    <TableCell>{dataByIndex[numTab].func(item, columnKey, onClickImage, changeBlocked, status, deleteReport)}</TableCell>}
            </TableRow>
        )
    }




    return (
        <DivWrapperBodyContentSC>
            <Table
                isHeaderSticky={true}
                aria-label="Example table with custom cells">
                <TableHeader columns={dataByIndex[numTab].columns}>
                    {(column) => (
                        <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody items={dataByIndex[numTab].items}>
                    {bodyRender}
                </TableBody>
            </Table>

            <Modal
                size={"5xl"}
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                scrollBehavior={'inside'}
                backdrop={'blur'}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Image</ModalHeader>
                            <ModalBody>
                                <Image
                                    // width={800}
                                    // height={500}
                                    alt="NextUI hero Image"
                                    src={image}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </DivWrapperBodyContentSC>
    )
};

export default BodyReports;