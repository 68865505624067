import React from 'react';
import {
    DivAvatarWrapperSc,
    DivDividerVerticalSC, DivMoreButtonSC,
    DivWrapperDash2SC,
    DivWrapperProjectNameSC, DivWrapperTabProjectsSC, SpanDateSc, SpanEmailSC, SpanUserNameSC
} from "../../styles/styles.users";
import hostName from "../../api/hostName";
import {CgNametag, CgWebsite} from "react-icons/cg";
import {useAppSelector} from "../../store/hooks";
import { CiUser } from "react-icons/ci";
import { MdContentPaste } from "react-icons/md";
import {DateTime} from "luxon";

const PostsTabs = () => {
    const {
        selectedUsersInfo
    } = useAppSelector(state => (state.usersReducer))

    return(
        <DivWrapperTabProjectsSC>
            {
                selectedUsersInfo && selectedUsersInfo.posts.map((item, i) => {
                    return(
                        <DivWrapperDash2SC
                            key={`dfsgsgsdf${i}`}
                            $isSelected={false}>

                            <DivWrapperProjectNameSC>
                                <SpanUserNameSC>
                                    <CiUser />
                                    <SpanDateSc>
                                        {
                                            DateTime.fromISO(item.createdAt).toFormat('ff')
                                        }
                                    </SpanDateSc>

                                </SpanUserNameSC>
                                <SpanEmailSC>
                                    <MdContentPaste />
                                    {
                                        item.textContent
                                    }
                                </SpanEmailSC>
                            </DivWrapperProjectNameSC>
                            <DivDividerVerticalSC/>
                            <DivMoreButtonSC>
                                More
                            </DivMoreButtonSC>
                        </DivWrapperDash2SC>
                    )
                })
            }

        </DivWrapperTabProjectsSC>
    )
};

export default PostsTabs;