import React from 'react';
import {useAppSelector} from "../../store/hooks";
import {
    DivAvatarWrapperSc,
    DivDividerVerticalSC, DivMoreButtonSC, DivWrapperDash2SC,
    DivWrapperDashSC, DivWrapperProjectNameSC,
    DivWrapperTabProjectsSC, DivWrapperUserNameSC, SpanEmailSC, SpanUserNameSC
} from "../../styles/styles.users";
import hostName from "../../api/hostName";
import {CgUser} from "react-icons/cg";
import {MdAlternateEmail} from "react-icons/md";
import { CgNametag } from "react-icons/cg";
import { CgWebsite } from "react-icons/cg";

const ProjectsTab = () => {
    const {
        selectedUsersInfo
    } = useAppSelector(state => (state.usersReducer))



    return(
        <DivWrapperTabProjectsSC>
            {
                selectedUsersInfo && selectedUsersInfo.projects.map((item, i) => {
                    return(
                        <DivWrapperDash2SC
                            key={`dfsgsgsdf${i}`}
                            $isSelected={false}>
                            <DivAvatarWrapperSc
                                $src={`${hostName.getHost()}/static/projects/${item.id}/${
                                    item.projectPhoto
                                }`}
                            />
                            <DivDividerVerticalSC/>
                            <DivWrapperProjectNameSC>
                                <SpanUserNameSC>
                                    <CgNametag />
                                    {
                                        item.projectName
                                    }
                                </SpanUserNameSC>
                                <SpanEmailSC>
                                    <CgWebsite />
                                    {
                                        item.websiteUrl
                                    }
                                </SpanEmailSC>
                            </DivWrapperProjectNameSC>
                            <DivDividerVerticalSC/>
                            <DivMoreButtonSC>
                                More
                            </DivMoreButtonSC>
                        </DivWrapperDash2SC>
                    )
                })
            }

        </DivWrapperTabProjectsSC>
    )
};

export default ProjectsTab;