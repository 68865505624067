import React from 'react';
import {DivWrapperProjectsSC} from "../styles/styles.projects";
import Index from "../components/projects";

const Projects = () => {
    return(
        <DivWrapperProjectsSC>
            <Index/>
        </DivWrapperProjectsSC>
    )
};

export default Projects;