import React, {useCallback, useEffect, useState} from 'react';
import {
    ButtonMoreSC,
    DivAvatarWrapperSc, DivDividerVerticalSC,
    DivWrapperDashSC, DivWrapperDateCreatedSC, DivWrapperDateSC, DivWrapperIconsAndTextVerifSC,
    DivWrapperListUsersSC, DivWrapperReputationSC, DivWrapperRoleSC, DivWrapperStatusVerifSC, DivWrapperUserNameSC,
    DivWrapperUsersListSC, SpanEmailSC, SpanUserNameSC
} from "../../styles/styles.users";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import hostName from "../../api/hostName";
import { CgUser } from "react-icons/cg";
import { MdAlternateEmail } from "react-icons/md";
import { FaUserSecret } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { DateTime } from "luxon";
import { FaEarthEurope } from "react-icons/fa6";
import { MdDomainVerification } from "react-icons/md";
import { MdDoNotDisturb } from "react-icons/md";
import {axiosGet} from "../../api/fetch";
import {getSelectedUser, IPost, IProject, IUser} from "../../store/slices/usersSlice";
import {getStatusLoad} from "../../store/slices/userSlice,";


import {ItemListProjects} from "../projects/itemListProjects";
import {MoreHorizontal24Filled} from "@fluentui/react-icons";
import {Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User} from "@nextui-org/react";
import {FiMoreHorizontal} from "react-icons/fi";
import Tools from "../../tools";

const columns = [
    { uid: "user", name: "User" },
    { uid: "userName", name: "Name Bio" },
    { uid: "created", name: "Created" },
    { uid: "role", name: "Role" },
    { uid: "updated", name: "Updated" },
    { uid: "status", name: "Status" },
    { uid: "actions", name: "Actions" },
];
const BlockListUsers = (props: any) => {

    const [sortedUsers, setSortedUsers] = useState<IUser[]>([])
    const {
        users,
        selectedUsersInfo
    } = useAppSelector(state => (state.usersReducer))

    const dispatch = useAppDispatch()

    useEffect(() => {
        let __users = Tools.clone(users)
        __users.sort(Tools.sortUserList)
        setSortedUsers(__users)
    }, [users]);
    const getUserInfo = (item: IUser) => async (e:any) => {

        try {
            dispatch(getStatusLoad(true))
            let data:{
                user: IUser;
                projects: IProject[];
                posts: IPost[];
            } = {
                user: item,
                projects: [],
                posts: []
            }
            const result = await axiosGet({
                url: `${hostName.getHost()}/user/${item.id}/projects`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                data.projects = result.data
                const result2 = await axiosGet({
                    url: `${hostName.getHost()}/user/${item.id}/posts`,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                    },
                })
                if (result2.status === 200) {
                    data.posts = result2.data

                    dispatch(getStatusLoad(false))
                    dispatch(getSelectedUser(data))
                }
                else if (result2.status === 400) {
                    dispatch(getStatusLoad(false))
                    dispatch(getSelectedUser(data))
                }
                // dispatch(setSelectedUserPosts(result.data))
            }
        }catch (err) {
            console.error('Error', err)
        }

    }
    const renderCell = useCallback((item: IUser, columnKey: any) => {
        // const cellValue = user[columnKey];

        switch (columnKey) {
            case "user":
                return (
                    <User
                        avatarProps={
                            {
                                radius: "lg",
                                src: `${hostName.getHost()}/static/profiles/${
                                    item.photo
                                }`
                            }
                        }
                        description={item.email}
                        name={item.userName.length > 20 ? item.userName.substr(0, 20) : item.userName}
                    >
                        {item.userName.length > 20 ? item.userName.substr(0, 20) : item.userName}
                    </User>
                );
            case 'userName':
                return (
                    <div className="flex flex-col">
                        <div>
                            {
                                `${item.firstName} ${item.lastName}`
                            }
                        </div>
                        <span>
                            {
                                `${item.bio}`
                            }
                        </span>
                    </div>
                );
            case "created":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.createdAt).toFormat('ff')
                        }
                    </div>
                );
            case "role":
                return (
                    <div className="flex flex-col">
                        {
                            item.role
                        }
                        {
                            item.role === 'admin' ?
                                <GrUserAdmin color={ '#0b8f58'}  /> :
                                <FaUserSecret color={'#115ef3'} />
                        }
                    </div>
                );
            case "updated":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.updatedAt).toFormat('ff')
                        }
                    </div>
                );
            case "status":
                return (
                    <div className="flex flex-col">
                        <div>
                            {
                                item.isProfessional ? <FaEarthEurope color={'#3dd5b1'} />
                                    : item.isVerified ? <MdDomainVerification color={'#115ef3'} /> :
                                        item.isEmailVereficated ? <MdAlternateEmail color={'green'} /> : <MdDoNotDisturb color={'red'}/>
                            }
                        </div>
                        <span>
                            {
                                item.isProfessional ? 'Professional'
                                    : item.isVerified ? 'Verified' :
                                        item.isEmailVereficated ? 'Email verified' : 'Not email verif'
                            }
                        </span>
                    </div>
                );
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        <Button
                            color="success"
                            // icon={<PresenceBlocked24Regular />}
                            onClick={getUserInfo(item)}
                            isIconOnly
                            variant="light"
                        >
                            <FiMoreHorizontal />
                        </Button>
                    </div>
                );
            default:
                return ;
        }
    }, []);


    return(
        <DivWrapperUsersListSC>
            <DivWrapperListUsersSC>
                <Table
                    isHeaderSticky={true}
                    aria-label="Example table with custom cells"
                    isStriped
                >
                    <TableHeader columns={columns}>
                        {(column) => (
                            <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                                {column.name}
                            </TableColumn>
                        )}
                    </TableHeader>
                    <TableBody items={sortedUsers}>
                        {(item) => (
                            <TableRow key={item.id}>
                                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>




            </DivWrapperListUsersSC>
        </DivWrapperUsersListSC>
    )
};

export default BlockListUsers;