import React from "react";
import {IReport} from "../../../store/slices/reportSlice";
import {DivImageReportSC} from "../../../styles/styles.reports";
import hostName from "../../../api/hostName";
import {Button, User} from "@nextui-org/react";
import HostName from "../../../api/hostName";
import {DateTime} from "luxon";

const RenderCellUsers = (
    item: IReport, columnKey: any, onClickImage: (url: string) => () => void,
    changeBlocked: (id: string) => () => void,
    isBlocked: boolean,
    deleteReport: (id: string) => () => void
) => {
    // const cellValue = user[columnKey];



    switch (columnKey) {
        case "imagePost":
            return (
                <div className="flex flex-col">
                    {
                        item.images.map((item2, i2) => {
                            return (
                                <DivImageReportSC
                                    $src={`${hostName.getHost()}/static/reports/${item2}`}
                                    key={`fdssgdlghg${i2}`}
                                    onClick={onClickImage(`${hostName.getHost()}/static/reports/${item2}`)}
                                />
                            )
                        })
                    }
                </div>
            );
        case "owner":
            return (
                <User
                    avatarProps={
                        {
                            radius: "lg",
                            src: `${HostName.getHost()}/static/profiles/${item.accused?.photo}`
                        }
                    }
                    // description={item.email}
                    name={item.accused?.userName}
                >
                    {item.accused?.userName}
                </User>
            );
        case "created":
            return (
                <div className="flex flex-col">
                    {
                        DateTime.fromISO(item.createdAt).toFormat('ff')
                    }
                </div>
            );
        case "postText":
            return (
                <div className="flex flex-col">
                    {item.post?.textContent}
                </div>
            );
        case "user":
            return (
                <User
                    avatarProps={
                        {
                            radius: "lg",
                            src: `${HostName.getHost()}/static/profiles/${item.claimant.photo}`
                        }
                    }
                    // description={item.email}
                    name={item.claimant.userName}
                >
                    {item.claimant.userName}
                </User>
            );
        case "message":
            return (
                <div className="flex flex-col">
                    {
                        item.message
                    }
                </div>
            );
        case "updated":
            return (
                <div className="flex flex-col">
                    {
                        DateTime.fromISO(item.updatedAt).toFormat('ff')
                    }
                </div>
            );

        case "actions":
            return (
                <div className="relative flex items-center gap-2">
                    {
                        !isBlocked ?
                            <Button
                                color="danger"
                                onClick={changeBlocked(item.accused? item.accused?.id : '')}

                            >
                                Ban the author
                            </Button>
                            :
                            <Button
                                color="success"
                                onClick={changeBlocked(item.accused? item.accused?.id : '')}

                            >
                                Unblock user
                            </Button>
                    }

                    <Button
                        color="success"
                        isDisabled={true}
                    >
                        Delete user
                    </Button>
                    <Button
                        color="success"
                        onClick={deleteReport(item.id)}
                    >
                        Deny
                    </Button>
                    <Button
                        color="success"
                        isDisabled={true}
                    >
                        Open
                    </Button>
                </div>
            );
        default:
            return ;
    }
};

export default RenderCellUsers