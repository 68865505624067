import styled from "styled-components";

export const DivWrapperProjectsSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    gap: 30px;
    box-sizing: border-box;
`

export const DivDashListProjectsSC = styled.div`
    display: grid;
    border-radius: 30px;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    background: #202124;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
`

export const DivDashListProjectslistSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 40px calc(100% - 40px);
    gap: 10px;
`
export const DivItemListProjectsSC = styled.div<{
    $isSelected: boolean
}>`
    display: grid;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    background: #36373a;
    grid-template-rows: 100%;
    grid-template-columns:repeat(14, max-content);
    padding: 15px;
    box-sizing: border-box;
    gap: 20px;
    box-shadow: ${({$isSelected}) => ($isSelected ? 'inset 0px 0px 20px 0px greenyellow' : 'none')} ;
`

export const DivBoxProjectInfoSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 30px 1fr;
    grid-template-columns: 100%;
    gap: 10px;
`

export const DivButtonBackSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-auto-rows: 100%;
    grid-template-columns: max-content 1fr;
    justify-items: center;
    align-items: center;
    
`

export const H4SC = styled.h2`
    margin: 0;
    color: darkgray;
`

export const ButtonBackSC = styled.button`
    border: none;
    background: transparent;
    width: max-content;
    height: max-content;
    color: white;
    cursor: pointer;
`

export const DivBoxColumnInfoProjectsSC = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    gap: 10px;
`

export const DivBoxColumnSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    overflow: auto;
`

export const DivContentColumnsSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    gap: 10px;
`


export const ItemInfoCellSC = styled.div`
    display: grid;
    width: calc(100% - 4px);
    height: max-content;
    border: 2px solid #797a7d;
    min-height: 50px;
    border-radius: 20px;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 100%;
    gap: 10px;
    align-items: center;
    overflow: hidden;
`

export const TitleColumnSC = styled.h3`
    text-align: center;
    color: darkgray;
`

export const DivTitleCellSC = styled.div`
    display: grid;
    //position: absolute;
    top: -7px;
    left: 20px;
    background: #202124;
    color: darkgray;
    padding-right: 10px;
    z-index: 10000;
    font-size: 15px;
    border-right: 2px solid #797a7d;
`

export const DivBoxDashSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    color: white;
    
`

export const AWrapperSC = styled.a`
    color: darkgray;
`

export const DivPhotoBoxSC = styled.div<{
    $image: string | null
}>`
    display: grid;
    width: 100px;
    height: 100px;
    background: url(${({$image}) => ($image ? $image : null)});
    background-size: cover;
`

export const DivBoxCheckSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content;
    justify-items: center;
    align-items: center;
    gap: 10px;
`

export const ButtonApproveSC = styled.button<{
    $color: string
}>`
    display: grid;
    color: white;
    padding: 5px;
    width: max-content;
    height: max-content;
    background: ${({$color}) => ($color)};
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    
`

export const DivWrapperListTagsSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-auto-columns: max-content;
    grid-auto-rows: max-content;
    gap: 5px;
`

export const DivWrapperTagsSC = styled.div`
    display: grid;
    width: max-content;
    height: 20px;
    background: greenyellow;
    color: #282c34;
    border-radius: 10px;
    padding: 5px;
    
`
export const ASC = styled.a`
    color: darkgray;
`

export const DivBoxAvatarProjectsSC = styled.div<{
    $src: string;
}>`
    display: grid;
    width: 50px;
    height: 100%;
    background: url(${({$src}) => ($src)});
    background-size: cover;
    border-radius: 50%;
    
`