import {axiosDelete, axiosGet, axiosPatch} from "../api/fetch";
import hostName from "../api/hostName";
import {getInitiatives, getNAInitiatives} from "../store/slices/InitiativesSlice";
import {
    getEventReports,
    getPostReports,
    getProjectReports,
    getTaskReports,
    getUserReports
} from "../store/slices/reportSlice";
import {getStatusLoad} from "../store/slices/userSlice,";
import {getUsers, IUser} from "../store/slices/usersSlice";
import {getUserBlocks, getUserUnblocks} from "../store/slices/blocksSlice";
import {makeStyles, shorthands} from "@fluentui/react-components";
import {getNAProjects, getProjects} from "../store/slices/projectsSlice";
import {IPostLike} from "../interfaces/postInterfaces";

const Tools = {
    getReportsUser: async (dispatch: any) => {
        try {
            const result = await axiosGet({
                url: `${hostName.getHost()}/user/report/user`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {

                dispatch(getUserReports(result.data))
            }
        } catch (err) {
            console.error(err)
        }

    },
    getReportsPosts: async (dispatch: any) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/user/report/post`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {

            dispatch(getPostReports(result.data))
        }
    },
    getReportsTasks: async (dispatch: any) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/user/report/task`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {

            dispatch(getTaskReports(result.data))
        }
    },
    getReportsEvents: async (dispatch: any) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/user/report/event`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {

            dispatch(getEventReports(result.data))
        }
    },
    getReportsProjects: async (dispatch: any) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/user/report/project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })
        if (result.status === 200) {
            dispatch(getProjectReports(result.data))
        }
    },
    changeBanUser: async (dispatch: any, id: string) => {

        try {
            dispatch(getStatusLoad(true))
            const result = await axiosPatch({
                url: `${hostName.getHost()}/admin/bl-ur`,
                data: {
                    userId: id,
                    message: ''
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                Tools.getAllUsers(dispatch)
                Tools.getBlockedUsers(dispatch)
                Tools.getUnblockedUsers(dispatch)
                Tools.getReportsUser(dispatch)
                Tools.getReportsPosts(dispatch)
                Tools.getReportsTasks(dispatch)
                Tools.getReportsEvents(dispatch)
                Tools.getReportsProjects(dispatch)
            }
            else {
                alert(result.data.message)
            }
        } catch (err) {
            console.error(err)
        }
    },
    getAllUsers: async (dispatch: any) => {
        try {
            const result = await axiosGet({
                url: `${hostName.getHost()}/user/all`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                dispatch(getUsers(result.data))
                // dispatch(clearSelectedUser())
                dispatch(getStatusLoad(false))

            }
        } catch (err) {
            console.error(err)
        }

    },
    getBlockedUsers: async (dispatch: any) => {
        dispatch(getStatusLoad(true))
        try {
            const result = await axiosGet({
                url: `${hostName.getHost()}/admin/g-blk`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                dispatch(getUserBlocks(result.data))
                // dispatch(clearSelectedUser())
                // dispatch(getStatusLoad(false))
                // console.log('sfsfsfsdfssfsf', result.data)

            }
        } catch (err) {
            console.error(err)
        }
        dispatch(getStatusLoad(false))
    },
    getUnblockedUsers: async (dispatch: any) => {
        dispatch(getStatusLoad(true))
        try {
            const result = await axiosGet({
                url: `${hostName.getHost()}/admin/g-ublk`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                dispatch(getUserUnblocks(result.data))
                // dispatch(clearSelectedUser())
                // dispatch(getStatusLoad(false))
                // console.log('sfsfsfsdfssfsf', result.data)

            }
        } catch (err) {
            console.error(err)
        }
        dispatch(getStatusLoad(false))
    },
    deleteReport: async (dispatch: any, id: string) => {
        dispatch(getStatusLoad(true))
        try {
            const result = await axiosDelete({
                url: `${hostName.getHost()}/admin/dny-rep/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                Tools.getReportsUser(dispatch)
                Tools.getReportsPosts(dispatch)
                Tools.getReportsTasks(dispatch)
                Tools.getReportsEvents(dispatch)
                Tools.getReportsProjects(dispatch)
                // dispatch(clearSelectedUser())
                // dispatch(getStatusLoad(false))
                // console.log('sfsfsfsdfssfsf', result.data)

            }
        } catch (err) {
            console.error(err)
        }
        dispatch(getStatusLoad(false))
    },
    sortUserList: (a: IUser, b: IUser) => {
        if (+new Date(a.updatedAt) > +new Date(b.updatedAt)) {
            return -1;
        }
        if (+new Date(a.updatedAt) < +new Date(b.updatedAt)) {
            return 1;
        }
        // a должно быть равным b
        return 0;
    },
    clone: (state: any) => {
        return JSON.parse(JSON.stringify(state));
    },
    getAllProjects: async (dispatch: any) => {
        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getProjects(result.data))
        }
    },
    getAllInitiatives: async (dispatch: any) => {
        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)

            dispatch(getInitiatives(result.data))
        }
    },
    getAllProjectsNotApprove: async (dispatch: any) => {
        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getNAProjects(result.data))
        }
    },
    getAllInitiativesNotApprove: async (dispatch: any) => {
        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)

            dispatch(getNAInitiatives(result.data))
        }
    },
    getAllRequestProjects: (dispatch: any) => {
        Tools.getAllProjects(dispatch)
        Tools.getAllInitiatives(dispatch)
        Tools.getAllProjectsNotApprove(dispatch)
        Tools.getAllInitiativesNotApprove(dispatch)
    },
    getAllPosts: async () => {
        return await axiosGet({
            url: `${hostName.getHost()}/post/all`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })

    },
    deletePost: async (id: string) => {
        return await axiosDelete({
            url: `${hostName.getHost()}/admin/del-post/${id}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
            },
        })

    },
    sortLikeList: (a: IPostLike, b: IPostLike) => {
        if (+new Date(a.updatedAt) > +new Date(b.updatedAt)) {
            return -1;
        }
        if (+new Date(a.updatedAt) < +new Date(b.updatedAt)) {
            return 1;
        }
        // a должно быть равным b
        return 0;
    },
}

export const useStylesTabs = makeStyles({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        ...shorthands.padding("0px", "0px"),
        rowGap: "20px",
    },
});

export default Tools