import React, {useEffect} from 'react';
import './App.css';
import {useNavigate} from "react-router-dom";

function App() {


    const navigate = useNavigate();
    useEffect(() => {
        navigate("/dashboard");
    }, [])


    return (
      <div className="App">

      </div>
    );
}

export default App;
