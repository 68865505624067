import {ButtonTabsHeaderSC, DivBoxHeaderContentSC} from "../../../styles/styles.reports";
import React, {useCallback} from "react";
import {useStylesTabs} from "../../../tools";
import {Tab, TabList} from "@fluentui/react-components";
import {Button, ButtonGroup} from "@nextui-org/react";

interface HeaderProps {
    numTab: number,
    setNum: (value: (((prevState: number) => number) | number)) => void
}

const arrayTab: string[] = [
    'Posts',
    'Tasks',
    'Events',
    'Users',
    'Projects'

]
export const Header = ({
                           numTab,
                           setNum
}: HeaderProps) => {
    const styles = useStylesTabs()

    const onButtonTabsHeaderSCClick = (num: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setNum(num)
    };

    const onSelectTabs = (val: number) => (e: any) => {

        setNum(val)
    }

    return (
        <DivBoxHeaderContentSC>
            <div className={styles.root}>
                <ButtonGroup>
                    {
                        arrayTab.map((item, i) => {
                            return (
                                <Button
                                    key={`asgasfd${i}`}
                                    isDisabled={numTab === i}
                                    onClick={onSelectTabs(i)}
                                >
                                    {item}
                                </Button>

                            )
                        })
                    }
                </ButtonGroup>
                {/*<TabList*/}
                {/*    selectedValue={numTab}*/}
                {/*    onTabSelect={onSelectTabs}*/}
                {/*>*/}
                {/*    {*/}
                {/*        arrayTab.map((item, i) => {*/}
                {/*            return (*/}
                {/*                <Tab*/}
                {/*                    value={i}*/}
                {/*                    key={`asgasfd${i}`}*/}
                {/*                >{item}</Tab>*/}

                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*</TabList>*/}
            </div>


        </DivBoxHeaderContentSC>
    );
};