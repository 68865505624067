import {DivDashListReportsSC, DivWrapperReportsSC} from "../styles/styles.reports";
import {PostsComponent} from "../components/posts/PostsComponent";

export const Posts = () => {
    return (
        <DivWrapperReportsSC>
            <DivDashListReportsSC>
                <PostsComponent/>
            </DivDashListReportsSC>
        </DivWrapperReportsSC>
    );
};