import { createSlice } from '@reduxjs/toolkit'

export interface IPostLike {
    id:string;
    createdAt:string;
    updatedAt:string;
    userId:string;
    postId:string;
    user: {
        id:string;
        userName:string;
        photo:string;
    }
}
export interface IUserPost {
    email:string;
    userName:string;
    fullName:string;
    bio:string;
    photo:string;
    isVerified:boolean;
    reputationScore:number;
    donation:number;
    contributedProjects:number;
}

export interface IComment {
    id: string;
    textContent: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    commentId:string | null;
    postId: string;
    comments: IComment[];
    user: {
        id: string;
        userName: string;
        photo: string;
    }
}
export interface IPost {
    comments: IComment[];
    createdAt: string;
    deletedAt: string | null;
    disLikes: number;
    id: string;
    images: [] | null;
    likes: IPostLike[];
    textContent: string;
    updatedAt: string;
    userId: string;
    user: IUserPost;
}
export interface IUser {
    bio: string;
    createdAt: string;
    customerId: string | null;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    isEmailVereficated: boolean;
    isProfessional: boolean;
    isVerified: boolean;
    password: string;
    photo: string;
    reputationScore: number
    role: string;
    updatedAt: string;
    userName: string;
    isBlocked: boolean;
}

export interface IProject {
    approved: boolean;
    createdAt: string;
    currentFundingStatus: string;
    einNumber: string;
    email: string;
    forecastsForTwoYears: string;
    founders:IFounders[];
    id: string;
    incorporationDocuments: string[];
    otherInformation: string;
    phoneNumber: string;
    projectDescription: string;
    projectGoalsAndObjectives: {
        targetAudience: string,
        brieflyDesc: string
    };
    projectName: string;
    projectPhoto: string;
    projectRoadmap: string;
    promotionalMaterials:  string[];
    socialMedia: {
        facebook: string;
        twitter: string;
        inst: string;
        linkedin: string;
    }
    updatedAt: string;
    ownerId: string;
    websiteUrl: string;
    owner: {
        id: string;
        userName: string;
        photo: string;
    }
}

export interface IFounders {
    idFounder: string;
    nameFounder: string;
    socialLinks: ISocialLink[];
}

export interface ISocialLink {
    idSocial: string;
    socialLink: string;
}
export interface IUsersSlice {
    users: IUser[];
    selectedUsersInfo: {
        user: IUser;
        projects: IProject[];
        posts: IPost[]
    } | null;
    selectedProjectsUser: IUser | null
}
const initialState:IUsersSlice = {
    users: [],
    selectedUsersInfo: null,
    selectedProjectsUser: null
}
export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {

        getUsers: (state, action) => {
            if (state.selectedUsersInfo) {
                const one = action.payload.find((item: any, i: number) => {
                    return item.id === state.selectedUsersInfo!.user.id
                })

                state.selectedUsersInfo = {
                    ...state.selectedUsersInfo,
                    user: one
                }
            }
            state.users = action.payload
        },
        getSelectedUser: (state, action) => {
            state.selectedUsersInfo = action.payload
        },
        clearSelectedUser: (state) => {
            state.selectedUsersInfo = null
        },
        getSelectedProjectUser: (state, action) => {
            state.selectedProjectsUser = action.payload
        },
        clearSelectedProjectUser: (state) => {
            state.selectedProjectsUser = null
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    getUsers,
    getSelectedUser,
    clearSelectedUser,
    getSelectedProjectUser,
    clearSelectedProjectUser
} = usersSlice.actions

const usersReducer = usersSlice.reducer
export default usersReducer