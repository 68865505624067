import {DivDashListBlocksSC, DivWrapperBlocksSC} from "../styles/styles.blocks";

import type { TabListProps } from "@fluentui/react-components";
import {BodyBlocks} from "../components/blocks/BodyBlocks";
import {useState} from "react";
import {BodyUnblock} from "../components/blocks/BodyUnblock";
import {Tabs, Tab, Card, CardBody} from "@nextui-org/react";




export const Blockeds = () => {
    const [tab, setTab] = useState<string>('tab1')

    const onSelectTabs = (e: any, p:any) => {
        console.log('dasfsfsdfasdsf', p)
        setTab(p.value)
    }

    return (
        <DivWrapperBlocksSC>
            <DivDashListBlocksSC>
                <Tabs aria-label="Options">
                    <Tab key="tab1" title="Users blocked">
                        <BodyBlocks/>
                    </Tab>
                    <Tab key="tab2" title="Users unblocked history">
                        <BodyUnblock/>
                    </Tab>
                </Tabs>


            </DivDashListBlocksSC>
        </DivWrapperBlocksSC>
    );
};