import React, {useEffect, useState} from 'react';
import {DivWrapperSelectSC, SelectWrapperSC} from "../../styles/styles.left_panel";
import hostName from "../../api/hostName";
import {getStatusLoad, getUser} from "../../store/slices/userSlice,";
import {axiosGet, axiosPost} from "../../api/fetch";
import {clearSelectedProjectUser, clearSelectedUser, getUsers} from "../../store/slices/usersSlice";
import {getNAProjects, getProjects} from "../../store/slices/projectsSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useNavigate} from "react-router-dom";
import {getInitiatives, getNAInitiatives} from "../../store/slices/InitiativesSlice";
import Tools from "../../tools";

const ProfileSelect = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [typeClient, setTypeClient] = useState<string>(localStorage.getItem('typeClient')!)

    useEffect(() => {
        if (localStorage.getItem('typeClient')) {
            setTypeClient(localStorage.getItem('typeClient')!)
        }

    }, [localStorage.getItem('typeClient')]);

    const onSubmit = async (e: any) => {
        dispatch(getStatusLoad(true))
        dispatch(clearSelectedUser())
        dispatch(clearSelectedProjectUser())
        let token = ''
        if (e.target.value === 'dev') {
            // token = localStorage.getItem('tokenDev')!
            localStorage.setItem('globalToken', localStorage.getItem('tokenDev')!)
            hostName.updateHost(hostName.staticDevHost)
        }
        if (e.target.value === 'prod') {
            // token = localStorage.getItem('token')!
            localStorage.setItem('globalToken', localStorage.getItem('token')!)
            hostName.updateHost(hostName.staticHost)

        }
        token = localStorage.getItem('globalToken')!
        localStorage.setItem('typeClient', e.target.value)

        const result2 = await axiosPost({
            url: `${hostName.getHost()}/auth/check`,
            data: {
                token: token,
            },
        })

        if (result2.status === 200) {
            if (result2.data.user.role === 'admin') {


                navigate("/");
                dispatch(getUser(result2.data.user))
                getAllUsers(token)
                getAllProjects(token)
                getAllProjectsNotApprove(token)
                getAllInitiatives(token)
                getAllInitiativesNotApprove(token)
                Tools.getReportsPosts(dispatch)
                Tools.getReportsUser(dispatch)
                Tools.getReportsTasks(dispatch)
                Tools.getReportsEvents(dispatch)
                Tools.getReportsProjects(dispatch)
                Tools.getBlockedUsers(dispatch)
                Tools.getUnblockedUsers(dispatch)
                console.log('><>>>><><>>2', localStorage.getItem('typeClient'))
                setTypeClient(localStorage.getItem('typeClient')!)
            }
            else {
                alert('permission denied')
            }

        }
        dispatch(getStatusLoad(false))

    }
    const getAllUsers = async (token: string) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/user/all`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        })
        if (result.status === 200) {
            console.log('><><>>><><>>><', result.data)
            dispatch(getUsers(result.data))
        }
    }

    const getAllProjects = async (token: string) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getProjects(result.data))
        }
    }

    const getAllProjectsNotApprove = async (token: string) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getNAProjects(result.data))
        }
    }

    const getAllInitiatives = async (token: string) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)

            dispatch(getInitiatives(result.data))
        }
    }

    const getAllInitiativesNotApprove = async (token: string) => {

        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)

            dispatch(getNAInitiatives(result.data))
        }
    }

    return (
        <DivWrapperSelectSC>
            {
                localStorage.getItem('token') && localStorage.getItem('tokenDev') &&
                <SelectWrapperSC
                    name="Profile"
                    id="Profile"
                    value={typeClient}
                    onChange={onSubmit}
                >
                    <option value="dev">Dev {localStorage.getItem('emailDev')} </option>
                    <option value="prod">Prod {localStorage.getItem('email')}</option>
                </SelectWrapperSC>
            }

        </DivWrapperSelectSC>
    )
};

export default ProfileSelect;