import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./slices/userSlice,";
import usersReducer from "./slices/usersSlice";
import projectsReducer from "./slices/projectsSlice";
import initiativesReducer from "./slices/InitiativesSlice";
import reportsReducer from "./slices/reportSlice";
import blocksReducer from "./slices/blocksSlice";


const store = configureStore({
    reducer: {
        userReducer,
        usersReducer,
        projectsReducer,
        initiativesReducer,
        reportsReducer,
        blocksReducer
    },
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store