import { createSlice } from '@reduxjs/toolkit'

export interface IUser {
    id: string;
    email: string;
    userName: string | null;
    fullName: string | null;
    firstName: string | null;
    lastName: string | null;
    bio: string | null;
    photo: string | null;
    isVerified: boolean;
    reputationScore: number;
    completedTasks: number;
    donation: number;
    contributedProjects: number;
    projectIds: [string] | null;
    isEmailVereficated: boolean;
    isProfessional: boolean;
    role: string;
    isBlocked: boolean;
}
export interface IUserSlice {
    user: IUser | null;
    isLoad: boolean;
}
const initialState:IUserSlice = {
    user: null,
    isLoad: false
}
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        getUser: (state, action) => {
            state.user = action.payload
        },
        getStatusLoad: (state, action) => {
            state.isLoad = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    getUser ,
    getStatusLoad
} = userSlice.actions

const userReducer = userSlice.reducer
export default userReducer