import React, {ReactNode, useEffect, useState} from 'react';
import {DivBackSpinnerSC, DivLogoutSc, DivWrapperLayoutSC} from "./styles/styles.layout";
import {axiosGet, axiosPost} from "./api/fetch";
import hostName from "./api/hostName";
import {getStatusLoad, getUser} from "./store/slices/userSlice,";
// import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./store/hooks";
import Panel from "./components/left_panel";
import {getUsers} from "./store/slices/usersSlice";
import {CircleLoader} from "react-spinners";
import {getNAProjects, getProjects} from "./store/slices/projectsSlice";
import {useLocation, useNavigate, useRoutes} from "react-router-dom";
import { LuLogOut } from "react-icons/lu";
import {getInitiatives, getNAInitiatives} from "./store/slices/InitiativesSlice";
import Tools from "./tools";
interface IProps {
    children: ReactNode
}

const Layout = (props: IProps) => {
    const {
        children
    } = props
    // const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const {
        isLoad,
        user
    } = useAppSelector(state => (state.userReducer))
    // const location = useLocation()
    let isReq = true
    useEffect(() => {
        if (localStorage.getItem('globalToken') && localStorage.getItem('typeClient') && isReq) {
            hostName.updateHost(localStorage.getItem('typeClient') === 'dev' ?  hostName.staticDevHost : hostName.staticHost)
            console.log('><>>><>>><>>')
            isReq = false
            getUserCheck()
        }
    }, [])

    const getUserCheck = async () => {
        dispatch(getStatusLoad(true))

        const result2 = await axiosPost({
            url: `${hostName.getHost()}/auth/check`,
            data: {
                token: localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token'),
            },
        })
        if (result2.status === 200) {
            if (result2.data.user.role === 'admin') {
                // navigate("/");
                setInterval(() => {

                    getAllUsers()
                    getAllProjects()
                    getAllProjectsNotApprove()
                    getAllInitiatives()
                    getAllInitiativesNotApprove()
                }, 30000)
                getAllUsers()
                getAllProjects()
                getAllProjectsNotApprove()
                getAllInitiatives()
                getAllInitiativesNotApprove()
                Tools.getReportsPosts(dispatch)
                Tools.getReportsUser(dispatch)
                Tools.getReportsTasks(dispatch)
                Tools.getReportsEvents(dispatch)
                Tools.getReportsProjects(dispatch)
                Tools.getBlockedUsers(dispatch)
                Tools.getUnblockedUsers(dispatch)
                dispatch(getUser(result2.data.user))
            }
            else {
                alert('permission denied')
                localStorage.removeItem('token')
                // navigate('/signin')

            }

        }
        else {
            localStorage.removeItem('token')
            // navigate('/signin')
        }
        dispatch(getStatusLoad(false))
    }

    const getAllUsers = async () => {
        console.log('fdfgsdfgsdfgdf22', localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token'))
        dispatch(getStatusLoad(true))
        const result = await axiosGet({
            url: `${hostName.getHost()}/user/all`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            console.log('><><>>><><>>><', result.data)
            dispatch(getStatusLoad(false))
            dispatch(getUsers(result.data))
        }
    }

    const getAllProjects = async () => {
        dispatch(getStatusLoad(true))
        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            dispatch(getStatusLoad(false))
            // console.log('><><>>><><>>><', result.data)
            dispatch(getProjects(result.data))
        }
    }

    const getAllInitiatives = async () => {
        dispatch(getStatusLoad(true))
        const result = await axiosGet({
            url: `${hostName.getHost()}/project/all?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getStatusLoad(false))
            dispatch(getInitiatives(result.data))
        }
    }

    const getAllProjectsNotApprove = async () => {
        dispatch(getStatusLoad(true))
        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=project`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            dispatch(getStatusLoad(false))
            // console.log('><><>>><><>>><', result.data)
            dispatch(getNAProjects(result.data))
        }
    }

    const getAllInitiativesNotApprove = async () => {
        dispatch(getStatusLoad(true))
        const result = await axiosGet({
            url: `${hostName.getHost()}/admin/g-na?type=initiative`,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('typeClient') === 'dev' ? localStorage.getItem('tokenDev') : localStorage.getItem('token')}`,
            },
        })
        if (result.status === 200) {
            // console.log('><><>>><><>>><', result.data)
            dispatch(getStatusLoad(false))
            dispatch(getNAInitiatives(result.data))
        }
    }


    console.log('sdfASf', !localStorage.getItem('token') && location.pathname !=='/signin', !localStorage.getItem('token'), location.pathname !=='/signin')

    if (!localStorage.getItem('globalToken') && location.pathname !=='/signin') {
        navigate('/signin', { state: { key: "value" } })
    }
    else if (location.pathname ==='/signin' && localStorage.getItem('globalToken')) {
        navigate('/')
    }



    if (location.pathname ==='/signin') {
        return (
            <DivWrapperLayoutSC
                $isLogin={false}
            >
                <div></div>
                {
                    children
                }
                {
                    isLoad ?
                        <DivBackSpinnerSC>
                            <CircleLoader color="#0b8f58"
                                          size={80}
                            />
                        </DivBackSpinnerSC> : null
                }

            </DivWrapperLayoutSC>
        )
    }

    return (
        <DivWrapperLayoutSC
            $isLogin={true}
        >

            <Panel />
            {
                children
            }
            {
                isLoad ?
                    <DivBackSpinnerSC>
                        <CircleLoader color="#0b8f58"
                                      size={80}
                        />
                    </DivBackSpinnerSC> : null
            }

        </DivWrapperLayoutSC>
    )
};

export default Layout;