import React from 'react';
import {
    ButtonCreateAdmin,
    DivBoxAdminSC,
    DivBoxAvatarNameSc,
    DivBoxAvatarSC,
    DivBoxInfoSC,
    DivBoxTabsSC,
    DivBoxTextSc,
    DivBoxUserEmailSC,
    DivBoxUserNameSC, DivButtonCloseSC,
    DivDividerHorizontSC,
    DivTempSC,
    DivWrapperInfoUsersSC,
    DivWrapperSmallBlockSC,
    SpanAdminSC
} from "../../styles/styles.users";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import hostName from "../../api/hostName";
import { FaCheck } from "react-icons/fa";
import {axiosGet, axiosPatch} from "../../api/fetch";
import {clearSelectedUser, getUsers} from "../../store/slices/usersSlice";
import {getStatusLoad} from "../../store/slices/userSlice,";
import Tabs from "./tabs";
import { MdDoNotDisturb } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import {Persona} from "@fluentui/react-components";
import {Button, User} from "@nextui-org/react";
import {FiMoreHorizontal} from "react-icons/fi";

const BlockInfo = (props: any) => {

    const {
        selectedUsersInfo
    } = useAppSelector(state => (state.usersReducer))
    const {
        user
    } = useAppSelector(state => (state.userReducer))
    const dispatch = useAppDispatch()

    const changeRole = async () => {
        try {
            dispatch(getStatusLoad(true))
            const result = await axiosPatch({
                url: `${hostName.getHost()}/admin/ch-rl`,
                data: {
                    adminId: user!.id,
                    userId: selectedUsersInfo!.user.id
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                console.log('><><>>>><<', result)
                getAllUsers()
            }
            else {
                alert(result.data.message)
            }
        } catch (err) {
            console.error(err)
        }

    }

    const changeProfi = async () => {
        try {
            dispatch(getStatusLoad(true))
            const result = await axiosPatch({
                url: `${hostName.getHost()}/admin/ch-prf`,
                data: {
                    userId: selectedUsersInfo!.user.id
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                console.log('><><>>>><<', result)
                getAllUsers()
            }
            else {
                alert(result.data.message)
            }
        } catch (err) {
            console.error(err)
        }

    }

    const changeBlock = async () => {
        try {
            dispatch(getStatusLoad(true))
            const result = await axiosPatch({
                url: `${hostName.getHost()}/admin/bl-ur`,
                data: {
                    userId: selectedUsersInfo!.user.id
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                console.log('><><>>>><<', result)
                getAllUsers()
            }
            else {
                alert(result.data.message)
            }
        } catch (err) {
            console.error(err)
        }

    }

    const changeVerif = async () => {
        try {
            dispatch(getStatusLoad(true))
            const result = await axiosPatch({
                url: `${hostName.getHost()}/admin/ch-ver`,
                data: {
                    userId: selectedUsersInfo!.user.id
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                console.log('><><>>>><<', result)
                getAllUsers()
            }
            else {
                alert(result.data.message)
            }
        } catch (err) {
            console.error(err)
        }

    }

    const getAllUsers = async () => {
        try {
            const result = await axiosGet({
                url: `${hostName.getHost()}/user/all`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('globalToken')}`,
                },
            })
            if (result.status === 200) {
                console.log('><><>>><><>>><', result.data)
                dispatch(getUsers(result.data))
                // dispatch(clearSelectedUser())
                dispatch(getStatusLoad(false))

            }
        } catch (err) {
            console.error(err)
        }

    }

    const onClose = () => {
        dispatch(clearSelectedUser())
    }



    return(
        <DivWrapperSmallBlockSC>
            {
                selectedUsersInfo &&
                <DivWrapperInfoUsersSC>
                    <DivBoxAvatarNameSc>

                        <User
                            avatarProps={{
                                src: `${hostName.getHost()}/static/profiles/${
                                    selectedUsersInfo.user?.photo
                                }`,
                                isBordered: true,
                                color:"success",
                                // className:"w-20 h-20 text-large",
                                radius:"sm"
                            }}
                            description={selectedUsersInfo.user.email}
                            name={selectedUsersInfo.user.userName ? selectedUsersInfo.user.userName : ''}
                            isFocusable={true}
                        />
                        {/*<DivBoxAvatarSC*/}
                        {/*    $src={`${hostName.getHost()}/static/profiles/${*/}
                        {/*        selectedUsersInfo.user.photo*/}
                        {/*    }`}*/}
                        {/*/>*/}
                        {/*<DivBoxInfoSC>*/}
                        {/*    <DivBoxUserNameSC>*/}
                        {/*        @{selectedUsersInfo.user.userName}*/}
                        {/*    </DivBoxUserNameSC>*/}
                        {/*    <DivBoxUserEmailSC>*/}
                        {/*        {*/}
                        {/*            selectedUsersInfo.user.email*/}
                        {/*        }*/}
                        {/*    </DivBoxUserEmailSC>*/}
                        {/*</DivBoxInfoSC>*/}
                    </DivBoxAvatarNameSc>
                    <DivDividerHorizontSC/>
                    <DivBoxAdminSC>
                        <SpanAdminSC>
                            Role: {selectedUsersInfo.user.role}
                        </SpanAdminSC>
                        {
                            selectedUsersInfo.user.role === 'admin' ?
                                <DivTempSC>
                                    <FaCheck color={'#0b8f58'}/>
                                    <div className="relative flex items-center gap-2">
                                        <Button
                                            color="danger"
                                            size="sm"
                                            variant="ghost"
                                            // icon={<PresenceBlocked24Regular />}
                                            onClick={changeRole}
                                        >
                                            Delete admin
                                        </Button>
                                    </div>


                                </DivTempSC> :
                                <div className="relative flex items-center gap-2">
                                    <Button
                                        color="success"
                                        size="sm"
                                        variant="ghost"
                                        // icon={<PresenceBlocked24Regular />}
                                        onClick={changeRole}
                                    >
                                        Create admin
                                    </Button>
                                </div>

                        }

                    </DivBoxAdminSC>
                    <DivDividerHorizontSC/>
                    <DivBoxAdminSC>
                        <SpanAdminSC>
                            Profi:
                        </SpanAdminSC>
                        {
                            selectedUsersInfo.user.isProfessional ?
                                <DivTempSC>
                                    <FaCheck color={'#0b8f58'}/>
                                    <div className="relative flex items-center gap-2">
                                        <Button
                                            color="danger"
                                            size="sm"
                                            variant="ghost"
                                            // icon={<PresenceBlocked24Regular />}
                                            onClick={changeProfi}
                                        >
                                            Delete profi
                                        </Button>
                                    </div>

                                </DivTempSC> :
                                <DivTempSC>
                                    <MdDoNotDisturb color={'#800505'}/>
                                    <div className="relative flex items-center gap-2">
                                        <Button
                                            color="success"
                                            size="sm"
                                            variant="ghost"
                                            // icon={<PresenceBlocked24Regular />}
                                            onClick={changeProfi}
                                            isDisabled={true}
                                        >
                                            Create profi
                                        </Button>
                                    </div>

                                </DivTempSC>

                        }

                    </DivBoxAdminSC>
                    <DivDividerHorizontSC/>
                    <DivBoxAdminSC>
                        <SpanAdminSC>
                            Blocked:
                        </SpanAdminSC>
                        {
                            selectedUsersInfo.user.isBlocked ?
                                <DivTempSC>
                                    <FaCheck color={'#0b8f58'}/>
                                    <div className="relative flex items-center gap-2">
                                        <Button
                                            color="danger"
                                            size="sm"
                                            variant="ghost"
                                            // icon={<PresenceBlocked24Regular />}
                                            onClick={changeBlock}
                                        >
                                            Delete blocked
                                        </Button>
                                    </div>

                                </DivTempSC> :
                                <DivTempSC>
                                    <MdDoNotDisturb color={'#800505'}/>
                                    <div className="relative flex items-center gap-2">
                                        <Button
                                            color="success"
                                            size="sm"
                                            variant="ghost"
                                            // icon={<PresenceBlocked24Regular />}
                                            onClick={changeBlock}
                                        >
                                            To block
                                        </Button>
                                    </div>

                                </DivTempSC>

                        }

                    </DivBoxAdminSC>
                    <DivDividerHorizontSC/>
                    <DivBoxAdminSC>
                        <SpanAdminSC>
                            Verification:
                        </SpanAdminSC>
                        {
                            selectedUsersInfo.user.isVerified ?
                                <DivTempSC>
                                    <FaCheck color={'#0b8f58'}/>

                                    <div className="relative flex items-center gap-2">
                                        <Button
                                            color="danger"
                                            size="sm"
                                            variant="ghost"
                                            // icon={<PresenceBlocked24Regular />}
                                            onClick={changeVerif}
                                        >
                                            Delete verification
                                        </Button>
                                    </div>
                                </DivTempSC> :
                                <DivTempSC>
                                    <MdDoNotDisturb color={'#800505'}/>
                                    <div className="relative flex items-center gap-2">
                                        <Button
                                            color="success"
                                            size="sm"
                                            variant="ghost"
                                            // icon={<PresenceBlocked24Regular />}
                                            onClick={changeVerif}
                                        >
                                            Create verification
                                        </Button>
                                    </div>

                                </DivTempSC>

                        }

                    </DivBoxAdminSC>
                    <DivDividerHorizontSC/>
                    <DivBoxAdminSC>
                        <SpanAdminSC>
                            Fullname
                        </SpanAdminSC>
                        <DivBoxTextSc>
                            {
                                `${selectedUsersInfo?.user.firstName} ${selectedUsersInfo?.user.lastName}`
                            }
                        </DivBoxTextSc>

                    </DivBoxAdminSC>
                    <DivDividerHorizontSC/>
                    <DivBoxAdminSC>
                        <SpanAdminSC>
                        Bio
                        </SpanAdminSC>
                        <DivBoxTextSc>
                            {
                                selectedUsersInfo.user.bio
                            }
                        </DivBoxTextSc>

                    </DivBoxAdminSC>
                    <DivDividerHorizontSC/>
                    <Tabs />
                </DivWrapperInfoUsersSC>
            }

        </DivWrapperSmallBlockSC>
    )

};

export default BlockInfo;