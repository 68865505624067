import styled from "styled-components";

export const DivWrapperLoginSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    background: #161616;
`

export const DivFormWrapperSC = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, max-content);
    width: 300px;
    height: max-content;
    min-height: 100px;
    border-radius: 20px;
    background: #202124;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
`

export const InputFormSC = styled.input`
    display: grid;
    //width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #1c1d1f;
    border: none;
    padding:0 10px;
    color: white;
    &:hover {
        outline: 1px solid darkgreen;
    }
    &:active {
        outline: 1px solid green;
    }
`

export const ButtonSC = styled.button`
    display: grid;
    background: #1a408a;
    width: 100%;
    height: 100%;
    border: none;
    color: white;
    justify-items: center;
    align-items: center;
    border-radius: 10px;
`

export const SpanTextSC = styled.span`
    color: darkgray;    
`

export const DivWrapperTabsSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
`

export const DivTabsSC = styled.div<{
    $isActive: boolean
}>`
    display: grid;
    justify-items: center;
    align-items: center;
    color: darkgray;
    border-radius: 10px;
    background: ${({$isActive}) => ($isActive ?'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(20,95,62,1) 0%, rgba(0,0,0,0) 100%)' : 'rgba(0,0,0,0)' )};
`