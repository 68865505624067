import { createSlice } from '@reduxjs/toolkit'

export interface IInitiative {
    approved: boolean;
    createdAt: string;
    currentFundingStatus: string;
    einNumber: string;
    email: string;
    forecastsForTwoYears: string;
    founders:IFounders[];
    id: string;
    incorporationDocuments: string[];
    otherInformation: string;
    phoneNumber: string;
    projectDescription: string;
    projectGoalsAndObjectives: {
        targetAudience: string,
        brieflyDesc: string
    };
    projectName: string;
    projectPhoto: string;
    projectRoadmap: string;
    promotionalMaterials:  string[];
    socialMedia: {
        facebook: string;
        twitter: string;
        inst: string;
        linkedin: string;
    }
    updatedAt: string;
    ownerId: string;
    websiteUrl: string;
    owner: {
        id: string;
        userName: string;
        photo: string;
    },
    tags: ITag[],
    subscribers: any[],
    type: string;
}

interface ITag {
    id: string
    tag: string
}
export interface IFounders {
    idFounder: string;
    nameFounder: string;
    socialLinks: ISocialLink[];
}

export interface ISocialLink {
    idSocial: string;
    socialLink: string;
}
export interface IInitiativesSlice {
    initiative: IInitiative[];
    notAppInitiative: IInitiative[];
    selectedInitiative: IInitiative | null;
}
const initialState:IInitiativesSlice = {
    initiative: [],
    notAppInitiative: [],
    selectedInitiative: null

}
export const initiativesSlice = createSlice({
    name: 'initiative',
    initialState,
    reducers: {

        getInitiatives: (state, action:any) => {
            if (state.selectedInitiative) {
                const one =  action.payload.find((element: any, index: number, array: any) => {

                    return (element.id === state.selectedInitiative!.id)
                })
                if (one) {
                    state.selectedInitiative = one
                }
            }

            state.initiative = action.payload
        },
        getNAInitiatives: (state, action) => {
            if (state.selectedInitiative) {
                const one =  action.payload.find((element: any, index: number, array: any) => {

                    return (element.id === state.selectedInitiative!.id)
                })
                if (one) {
                    state.selectedInitiative = one
                }
            }
            state.notAppInitiative = action.payload
        },
        selectInitiative: (state, action) => {
            state.selectedInitiative = action.payload
        },


    },
})

// Action creators are generated for each case reducer function
export const {
    getInitiatives,
    getNAInitiatives,
    selectInitiative
} = initiativesSlice.actions

const initiativesReducer = initiativesSlice.reducer
export default initiativesReducer