const hostName = {
    host: 'https://api.earthy.tech',
    staticHost: 'https://api.earthy.tech',
    staticDevHost: 'https://api-dev.earthy.tech',
    getHost: () => {
        return hostName.host
    },
    updateHost: (host: string) => {
        hostName.host = host ? host : hostName.staticHost
    }
}

export default hostName