import {createSlice} from '@reduxjs/toolkit'
import {IProject} from "./projectsSlice";

export interface IBlock {
    createdAt: string;
    id: string;
    isNew: boolean;
    messageFromUser: string | null;
    reasonForBan: string | null;
    status: string;
    updatedAt: string;
    user: IUserBlock
    userId: string;
}

export interface IUserBlock {
    email: string;
    id: string;
    photo: string;
    userName: string;
}

export interface IBlocksSlice {
    blockUsers: IBlock[];
    unblockUsers: IBlock[];

}

const initialState: IBlocksSlice = {
    blockUsers: [],
    unblockUsers: []
}
export const bLocksSlice = createSlice({
    name: 'blocks',
    initialState,
    reducers: {

        getUserBlocks: (state, action) => {
            // if (state.selectedUsersInfo) {
            //     const one = action.payload.find((item: any, i: number) => {
            //         return item.id === state.selectedUsersInfo!.user.id
            //     })
            //
            //     state.selectedUsersInfo = {
            //         ...state.selectedUsersInfo,
            //         user: one
            //     }
            // }
            state.blockUsers = action.payload
        },
        getUserUnblocks: (state, action) => {
            // if (state.selectedUsersInfo) {
            //     const one = action.payload.find((item: any, i: number) => {
            //         return item.id === state.selectedUsersInfo!.user.id
            //     })
            //
            //     state.selectedUsersInfo = {
            //         ...state.selectedUsersInfo,
            //         user: one
            //     }
            // }
            state.unblockUsers = action.payload
        },


    },
})

// Action creators are generated for each case reducer function
export const {
    getUserBlocks,
    getUserUnblocks,

} = bLocksSlice.actions

const blocksReducer = bLocksSlice.reducer
export default blocksReducer