import React from 'react';
import {DivTabSC, DivWrapperTabListSC} from "../../styles/styles.left_panel";
import {useLocation} from "react-router-dom";

interface ITab {
    name: string;
    key: string;
}

const listTabs: ITab[] = [
    {
        name: 'Dashboard',
        key: '/dashboard'
    },
    {
        name: 'Users',
        key: '/users'
    },
    {
        name: 'Projects',
        key: '/projects'
    },
    {
        name: 'Reports',
        key: '/reports'
    },
    {
        name: 'Blocks',
        key: '/blocks'
    },
    {
        name: 'Posts',
        key: '/posts'
    }
]
const TabList = (props: any) => {
    const location = useLocation()
    return(
        <DivWrapperTabListSC>
            {
                listTabs.map((item, i) => {
                    return(
                        <DivTabSC
                            to={item.key}
                            key={`dff${item.key}, ${i}`}
                            $isActive={location.pathname === item.key}
                        >
                            {item.name}
                        </DivTabSC>

                    )
                })
            }

        </DivWrapperTabListSC>
    )
};

export default TabList;