import styled from "styled-components";

export const DivWrapperBlocksSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    box-sizing: border-box;
`

export const DivDashListBlocksSC = styled.div`
    display: grid;
    border-radius: 30px;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    background: #202124;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
`

export const DivTemSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
`