import { createSlice } from '@reduxjs/toolkit'

export interface IProject {
    approved: boolean;
    createdAt: string;
    currentFundingStatus: string;
    einNumber: string;
    email: string;
    forecastsForTwoYears: string;
    founders:IFounders[];
    id: string;
    incorporationDocuments: string[];
    otherInformation: string;
    phoneNumber: string;
    projectDescription: string;
    projectGoalsAndObjectives: {
        targetAudience: string,
        brieflyDesc: string
    };
    projectName: string;
    projectPhoto: string;
    projectRoadmap: string;
    promotionalMaterials:  string[];
    socialMedia: {
        facebook: string;
        twitter: string;
        inst: string;
        linkedin: string;
    }
    updatedAt: string;
    ownerId: string;
    websiteUrl: string;
    owner: {
        id: string;
        userName: string;
        photo: string;
    },
    tags: ITag[];
    subscribers?: string[];
    type: string;
}

interface ITag {
    id: string
    tag: string
}
export interface IFounders {
    idFounder: string;
    nameFounder: string;
    socialLinks: ISocialLink[];
}

export interface ISocialLink {
    idSocial: string;
    socialLink: string;
}
export interface IProjectsSlice {
    projects: IProject[];
    notAppProjects: IProject[];
    selectedProject: IProject | null;
}
const initialState:IProjectsSlice = {
    projects: [],
    notAppProjects: [],
    selectedProject: null

}
export const projectsSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        getProjects: (state, action:any) => {
            if (state.selectedProject) {
                const one =  action.payload.find((element: any, index: number, array: any) => {

                    return (element.id === state.selectedProject!.id)
                })
                if (one) {
                    state.selectedProject = one
                }
            }

            state.projects = action.payload
        },
        getNAProjects: (state, action) => {
            if (state.selectedProject) {
                const one =  action.payload.find((element: any, index: number, array: any) => {

                    return (element.id === state.selectedProject!.id)
                })
                if (one) {
                    state.selectedProject = one
                }
            }
            state.notAppProjects = action.payload
        },
        selectProject: (state, action) => {
            state.selectedProject = action.payload
        },


    },
})

// Action creators are generated for each case reducer function
export const {
    getProjects,
    getNAProjects,
    selectProject
} = projectsSlice.actions

const projectsReducer = projectsSlice.reducer
export default projectsReducer