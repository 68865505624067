

import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {DivTemSC} from "../../styles/styles.blocks";
import hostName from "../../api/hostName";
import Tools from "../../tools";
import {DateTime} from "luxon";
import {Button, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, User} from "@nextui-org/react";
import {IBlock} from "../../store/slices/blocksSlice";


const columns = [
    { uid: "user", name: "User blocked" },
    { uid: "created at", name: "Created block" },
    { uid: "message", name: "Message" },
    { uid: "actions", name: "Actions" },
];


export const BodyBlocks = () => {


    const {
        blockUsers
    } = useAppSelector(state => state.blocksReducer)
    const dispatch = useAppDispatch()

    console.log('><>>><>>><>>', blockUsers)

    const onCLickUserBan = (id: string) => () => {
        Tools.changeBanUser(dispatch, id)
    }

    const renderCell = (item: IBlock, columnKey: any) => {


        switch (columnKey) {
            case "user":
                return (
                    <User
                        avatarProps={{radius: "lg", src: `${hostName.getHost()}/static/profiles/${item.user.photo}`}}
                        description={item.user.email}
                        name={item.user.userName}
                    >
                        {item.user.email}
                    </User>
                );
            case "created at":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.createdAt).toFormat('ff')
                        }
                    </div>
                );
            case "message":
                return (
                    <div className="flex flex-col">
                        {
                            item.messageFromUser
                        }
                    </div>
                );
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        <Button
                            color="success"
                            // icon={<PresenceBlocked24Regular />}
                            onClick={onCLickUserBan(item.user.id)}
                        >
                            Unblock user
                        </Button>
                    </div>
                );
            default:
                return ;
        }
    };

    return (
        <DivTemSC>
            <Table isStriped aria-label="Example table with custom cells">
                <TableHeader columns={columns}>
                    {(column) => (
                        <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody items={blockUsers}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </DivTemSC>

    );
};