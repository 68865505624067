import {createSlice} from '@reduxjs/toolkit'
import {IProject} from "./projectsSlice";

export interface IUserReport {
    id: string;
    photo: string;
    userName: string;
}

export interface IPostReport {
    createdAt: string;
    deletedAt: null
    eventId: null
    id: string;
    images: string[]
    projectId: null
    repostId: null
    taskId: null
    textContent: string;
    threads: []
    updatedAt: string;
    user: IUserReport;
    userId: string;
}

export interface ITaskReport {
    address: string;
    bountyAmount: string;
    bountyCurrency: string;
    considerLink: string | null;
    contractorId: string | null;
    createdAt: string;
    description: string;
    dueDate: string;
    id: string;
    images: string[];
    isDone: boolean;
    location: string;
    project: IProject;
    projectId: string;
    status: string;
    taskName: string;
    type: string;
    updatedAt: string;
}

export interface IEventReport {
    address: string;
    coordinates: string[] | null
    createdAt: string;
    description: string;
    endDate: string;
    eventName: string;
    id: string;
    images: string[];
    isOver: boolean;
    location: string;
    project: IProject;
    projectId: string;
    startDate: string;
    type: string;
    typeOfMeeting: string;
    updatedAt: string;
}


export interface IReport {
    accused: IUserReport | null;
    accusedId: string | null;
    claimant: IUserReport;
    claimantId: string;
    createdAt: string;
    deletedAt: string | null;
    event: IEventReport | null;
    eventId: string | null;
    id: string;
    images: string[];
    message: string;
    post: IPostReport | null;
    postId: string | null;
    project: IProject | null;
    projectId: string | null;
    task: ITaskReport | null;
    taskId: string | null;
    type: string;
    updatedAt: string;
}

export interface IReportsSlice {
    userReports: IReport[];
    postReports: IReport[];
    taskReports: IReport[];
    eventReports: IReport[];
    projectReports: IReport[];
}

const initialState: IReportsSlice = {
    userReports: [],
    postReports: [],
    taskReports: [],
    eventReports: [],
    projectReports: []
}
export const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {

        getUserReports: (state, action) => {
            // if (state.selectedUsersInfo) {
            //     const one = action.payload.find((item: any, i: number) => {
            //         return item.id === state.selectedUsersInfo!.user.id
            //     })
            //
            //     state.selectedUsersInfo = {
            //         ...state.selectedUsersInfo,
            //         user: one
            //     }
            // }
            state.userReports = action.payload
        },
        getPostReports: (state, action) => {
            // if (state.selectedUsersInfo) {
            //     const one = action.payload.find((item: any, i: number) => {
            //         return item.id === state.selectedUsersInfo!.user.id
            //     })
            //
            //     state.selectedUsersInfo = {
            //         ...state.selectedUsersInfo,
            //         user: one
            //     }
            // }
            state.postReports = action.payload
        },
        getTaskReports: (state, action) => {
            // if (state.selectedUsersInfo) {
            //     const one = action.payload.find((item: any, i: number) => {
            //         return item.id === state.selectedUsersInfo!.user.id
            //     })
            //
            //     state.selectedUsersInfo = {
            //         ...state.selectedUsersInfo,
            //         user: one
            //     }
            // }
            state.taskReports = action.payload
        },
        getEventReports: (state, action) => {
            // if (state.selectedUsersInfo) {
            //     const one = action.payload.find((item: any, i: number) => {
            //         return item.id === state.selectedUsersInfo!.user.id
            //     })
            //
            //     state.selectedUsersInfo = {
            //         ...state.selectedUsersInfo,
            //         user: one
            //     }
            // }
            state.eventReports = action.payload
        },
        getProjectReports: (state, action) => {
            // if (state.selectedUsersInfo) {
            //     const one = action.payload.find((item: any, i: number) => {
            //         return item.id === state.selectedUsersInfo!.user.id
            //     })
            //
            //     state.selectedUsersInfo = {
            //         ...state.selectedUsersInfo,
            //         user: one
            //     }
            // }
            state.projectReports = action.payload
        },
        getSelectedUser: (state, action) => {
            // state.selectedUsersInfo = action.payload
        },
        clearSelectedUser: (state) => {
            // state.selectedUsersInfo = null
        },
        getSelectedProjectUser: (state, action) => {
            // state.selectedProjectsUser = action.payload
        },
        clearSelectedProjectUser: (state) => {
            // state.selectedProjectsUser = null
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    getUserReports,
    getPostReports,
    getTaskReports,
    getEventReports,
    getProjectReports

} = reportSlice.actions

const reportsReducer = reportSlice.reducer
export default reportsReducer