import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Layout from "./layout";
import {Provider} from 'react-redux'
import store from "./store/store";
import {
    Routes,
    BrowserRouter,
    Route
} from "react-router-dom";
import Signin from "./pages/signin";
import ErrorPage from "./pages/error-page";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Projects from "./pages/projects";
import Reports from "./pages/reports";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {Blockeds} from "./pages/Messages";
import {
    FluentProvider,
    webLightTheme,
    BrandVariants,
    createLightTheme,
    createDarkTheme,
    Theme
} from "@fluentui/react-components";
import {NextUIProvider, nextui} from '@nextui-org/react'
import {ThemeProvider as NextThemesProvider} from "next-themes";
import {Posts} from "./pages/Posts";
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const darkkirdro: BrandVariants = {
    10: "#020203",
    20: "#161618",
    30: "#232427",
    40: "#2F3033",
    50: "#3B3C3F",
    60: "#47484B",
    70: "#08a563",
    80: "#626265",
    90: "#6F7072",
    100: "#7D7E80",
    110: "#8B8C8E",
    120: "#9A9A9C",
    130: "#A8A9AA",
    140: "#B7B7B9",
    150: "#C6C7C7",
    160: "#D6D6D7"
};

const lightTheme: Theme = {
    ...createLightTheme(darkkirdro),
};

const darkTheme: Theme = {
    ...createDarkTheme(darkkirdro),
};


darkTheme.colorBrandForeground1 = darkkirdro[110];
darkTheme.colorBrandForeground2 = darkkirdro[120];
const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter basename="/">
                    <NextUIProvider

                    >
                        <NextThemesProvider attribute="class" defaultTheme="dark">
                            <DevSupport ComponentPreviews={ComponentPreviews}
                                        useInitialHook={useInitial}
                            >
                                <Layout>
                                    <Routes>
                                        <Route path="/" errorElement={<ErrorPage/>} element={<App/>}/>
                                        <Route path="/signin" element={<Signin/>}/>
                                        <Route path="/dashboard" errorElement={<ErrorPage/>} element={<Dashboard/>}/>
                                        <Route path="/users" errorElement={<ErrorPage/>} element={<Users/>}/>
                                        <Route path="/projects" errorElement={<ErrorPage/>} element={<Projects/>}/>
                                        <Route path="/reports" errorElement={<ErrorPage/>} element={<Reports/>}/>
                                        <Route path="/blocks" errorElement={<ErrorPage/>} element={<Blockeds/>}/>
                                        <Route path="/posts" errorElement={<ErrorPage/>} element={<Posts/>}/>

                                    </Routes>
                                </Layout>
                            </DevSupport>
                        </NextThemesProvider>

                    </NextUIProvider>


                </BrowserRouter>

            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>



    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
