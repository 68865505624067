import axios from 'axios'

export interface IPAxiosPost {
	url: string
	data: { [x: string]: any }
	headers?: any
}

export interface IPAxiosGet {
	url: string
	headers?: any
}

export interface IPAxiosPatch {
	url: string
	data: { [x: string]: any }
	headers?: any
}
export interface IPAxiosDelete {
	url: string
	headers?: any
}

// @ts-nocheck
export const axiosPost = async (params: IPAxiosPost) => {
	try {
		// @ts-ignore

		return axios
			.post(params.url, params.data, {
				headers: params.headers ? params.headers : '',
			})
			.then(response => {
				return response
			})
			.catch(error => {
				console.log(error)
				return error.response
			})
	} catch (error) {
		console.log('>>>>>>>>>>222', error)
	}
}


export const axiosGet = async (params: IPAxiosGet) => {
	try {
		// @ts-ignore
		return axios
			.get(params.url, {
				headers: params.headers ? params.headers : '',
			})
			.then(response => {
				return response
			})
			.catch(error => {
				console.log(error)
				return error.response
			})
	} catch (error) {
		console.log('>>>>>>>>>>222', error)
	}
}

export const axiosPatch = async (params: IPAxiosPatch) => {
	try {
		// @ts-ignore
		return axios
			.patch(params.url, params.data, {
				headers: params.headers ? params.headers : '',
			})
			.then(response => {
				return response
			})
			.catch(error => {
				console.log(error)
				return error.response
			})
	} catch (error) {
		console.log('>>>>>>>>>>222', error)
	}
}

export const axiosDelete = async (params: IPAxiosDelete) => {
	try {
		// @ts-ignore
		return axios
			.delete(params.url, {
				headers: params.headers ? params.headers : '',
			})
			.then(response => {
				return response
			})
			.catch(error => {
				console.log(error)
				return error.response
			})
	} catch (error) {
		console.log('>>>>>>>>>>222', error)
	}
}
