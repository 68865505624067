import styled from "styled-components";

export const DivWrapperLayoutSC = styled.div<{
    $isLogin: boolean
}>`
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-rows: 100%;
    grid-template-columns: ${({$isLogin}) => $isLogin ? '250px 1fr' : '1px 1fr'}
`

export const DivBackSpinnerSC = styled.div`
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    justify-items: center;
    align-items: center;
`

export const DivLogoutSc = styled.div`
    display: grid;
    //position: absolute;
    left: 50px;
    bottom: 50px;
    width: max-content;
    height: max-content;
    background: transparent;
    color: white;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 100%;
    gap: 10px;
    cursor: pointer;
    padding-left: 30px;
    box-sizing: border-box;
`

export const DivBackLoginSC = styled.div`
    display: grid;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0,0,0,.5);
    //z-index: 100000000;
    justify-items: center;
    align-items: center;
`

export const DivItemTempSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content;
    justify-items: center;
    align-items: center;
    gap: 8px;
`