import {DivWrapperPostsSC} from "../../styles/styes.posts";
import {

    useQuery, useQueryClient,
} from '@tanstack/react-query'
import Tools from "../../tools";
import React, { useEffect, useState} from "react";
import {getStatusLoad} from "../../store/slices/userSlice,";
import {useAppDispatch} from "../../store/hooks";
import {
    Button, Modal, ModalBody,
    ModalContent, ModalFooter, ModalHeader,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow, useDisclosure,
    User
} from "@nextui-org/react";
import hostName from "../../api/hostName";
import {DateTime} from "luxon";
import {MdOutlineDeleteOutline} from "react-icons/md";
import {IPost, IPostLike} from "../../interfaces/postInterfaces";
import {Image} from "@nextui-org/react";
import { FcLike } from "react-icons/fc";


const columns = [
    { uid: "content", name: "Content" },
    { uid: "images", name: "Images" },
    { uid: "created", name: "Created" },
    { uid: "owner", name: "Owner" },
    { uid: "likes", name: "Likes" },
    { uid: "actions", name: "Actions" },
];

const columnsLikes = [
    { uid: "user", name: "User" },
    { uid: "created", name: "Created" },
    { uid: "updated", name: "Updated" },
];
export const PostsComponent = () => {
    const {
        isPending,
        fetchStatus,
        error,
        data,
        isFetching,
        refetch
    } = useQuery({
        queryKey: ['PostsData', localStorage.getItem('globalToken')],
        queryFn: () => Tools.getAllPosts(),
        staleTime: 5 * 1000,
        // refetchInterval: 5000
    })
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {isOpen: isOpenLikes, onOpen: onOpenLikes, onOpenChange: onOpenChangeLikes} = useDisclosure();
    const queryClient = useQueryClient()
    const [selectedImages, setSelectedImages] = useState<string[]>([])
    const [selectedLikes, setSelectedLikes] = useState<IPostLike[]>([])

    const [listPosts, setListPosts] = useState<IPost[]>([])
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getStatusLoad(isFetching))
    }, [isFetching]);

    useEffect(() => {
        if (data && data.data && Array.isArray(data.data)) {
            setListPosts(data.data)
        }
        else {
            setListPosts([])
        }
    }, [data]);



    const renderCell = (item: IPost, columnKey: any) => {
        // const cellValue = user[columnKey];
        const onDeletePost =  (id: string) => async () => {
            await queryClient.prefetchQuery({
                queryKey: ['DeletePost', item.id],
                queryFn: () => Tools.deletePost(item.id),
                staleTime: 10 * 1000, // only prefetch if older than 10 seconds
            })
            refetch()
        }

        const _selectImages = () => {
            setSelectedImages(item.images)
            onOpen()
        }

        const _selectLikes = () => {
            const _like = Tools.clone(item.likes)
            _like.sort(Tools.sortLikeList)
            setSelectedLikes(_like)
            onOpenLikes()
        }
        switch (columnKey) {
            case "content":
                return (
                    <div className="flex flex-col">
                        {
                            item.textContent
                        }
                    </div>
                );
            case "images":
                return (
                    <div className="relative flex items-center gap-2" onClick={_selectImages}>
                        {
                            item.images.map((_item, i) => {
                                return(
                                    <Image
                                        key={`imagelkjlkj${i}`}
                                        radius={'sm'}
                                        width={70}
                                        // height={100}
                                        alt="NextUI hero Image with delay"
                                        src={`${hostName.getHost()}/static/posts/${_item}`}
                                    />
                                )
                            })
                        }
                    </div>
                );
            case "created":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.createdAt).toFormat('ff')
                        }
                    </div>
                );

            case "owner":
                return (
                    <User
                        avatarProps={
                            {
                                radius: "lg",
                                src: `${hostName.getHost()}/static/profiles/${item.user.photo}`
                            }
                        }
                        // description={item.user.email}
                        name={item.user.userName.length > 20 ? item.user.userName.substr(0, 20) : item.user.userName}
                    >
                        {item.user.userName.length > 20 ? item.user.userName.substr(0, 20) : item.user.userName}
                    </User>
                );
            case "likes":
                return (
                    <div className="relative flex items-center gap-2 cursor-pointer" onClick={_selectLikes}>
                        <div>
                            <FcLike />
                        </div>
                        <div>
                            {
                                item.likes.length
                            }
                        </div>

                    </div>
                );
            case "actions":
                return (
                    <div className="relative flex items-center gap-2">
                        {/*<Button*/}
                        {/*    color="success"*/}
                        {/*    // icon={<PresenceBlocked24Regular />}*/}
                        {/*    // onClick={getUserInfo(item)}*/}
                        {/*    isIconOnly*/}
                        {/*    variant="light"*/}

                        {/*>*/}
                        {/*    <FiMoreHorizontal />*/}
                        {/*</Button>*/}
                        <Button
                            color="danger"
                            // icon={<PresenceBlocked24Regular />}
                            onClick={onDeletePost(item.id)}
                            isIconOnly
                            variant="light"
                        >
                            <MdOutlineDeleteOutline
                                size={20}
                            />
                        </Button>
                    </div>
                );
            default:
                return ;
        }
    };

    const renderCellLikes = (item: IPostLike, columnKey: any) => {
        // const cellValue = user[columnKey];

        switch (columnKey) {

            case "created":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.createdAt).toFormat('ff')
                        }
                    </div>
                );
            case "updated":
                return (
                    <div className="flex flex-col">
                        {
                            DateTime.fromISO(item.updatedAt).toFormat('ff')
                        }
                    </div>
                );

            case "user":
                return (
                    <User
                        avatarProps={
                            {
                                radius: "lg",
                                src: `${hostName.getHost()}/static/profiles/${item.user.photo}`
                            }
                        }
                        // description={item.user.email}
                        name={item.user.userName.length > 20 ? item.user.userName.substr(0, 20) : item.user.userName}
                    >
                        {item.user.userName.length > 20 ? item.user.userName.substr(0, 20) : item.user.userName}
                    </User>
                );

            default:
                return ;
        }
    };

    return (
        <DivWrapperPostsSC>
            <Table
                isHeaderSticky={true}
                layout={'fixed'}
                isStriped
                aria-label="Example table with custom cells">

                <TableHeader columns={columns}>
                    {(column) => (
                        <TableColumn maxWidth={'300'} key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody items={listPosts}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Modal
                size={"5xl"}
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                scrollBehavior={'inside'}
                backdrop={'blur'}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Image</ModalHeader>
                            <ModalBody>
                                {selectedImages.map((_item, i) => {
                                    return(
                                        <Image
                                            key={`imageldsfkjlkj${i}`}
                                            radius={'sm'}
                                            // width={70}
                                            // height={100}
                                            alt="NextUI hero Image with delay"
                                            src={`${hostName.getHost()}/static/posts/${_item}`}
                                        />
                                    )
                                })}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>

            </Modal>
            <Modal
                size={"5xl"}
                isOpen={isOpenLikes}
                onOpenChange={onOpenChangeLikes}
                scrollBehavior={'inside'}
                backdrop={'blur'}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Likes</ModalHeader>
                            <ModalBody>
                                <Table
                                    isHeaderSticky={true}
                                    layout={'fixed'}
                                    isStriped
                                    aria-label="Example table with custom cells">

                                    <TableHeader columns={columnsLikes}>
                                        {(column) => (
                                            <TableColumn maxWidth={'300'} key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                                                {column.name}
                                            </TableColumn>
                                        )}
                                    </TableHeader>
                                    <TableBody items={selectedLikes}>
                                        {(item) => (
                                            <TableRow key={item.id}>
                                                {(columnKey) => <TableCell>{renderCellLikes(item, columnKey)}</TableCell>}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </DivWrapperPostsSC>
    );
};